<template>
  <!-- 卡片视图区域 -->
  <el-card class="card">
    <el-dialog title="二维码" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <div id="rccode">
        <h4 style="display: flex;justify-content: center;">链接地址： <span>{{ url }}</span></h4>
        <div class="codetu" style="display: flex;justify-content: center;">
          <VueQr :text='url' style="height: 22vh"></VueQr>
        </div>
        <div class="list" style="display: flex;justify-content: center;">
          {{ dianzhuanghao }}
        </div>
      </div>
    </el-dialog>
    <el-form class="form" :inline="true" :model="queryInfo">
      <div>
        <el-form-item label="所属电站">
          <el-select v-model="xuanzedianzhan" placeholder="请选择电站" @change="sousuo"
             filterable>
            <el-option v-for="item in station_list_all" :key="item.stationId" :label="item.stationName"
              :value="item.stationId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电桩编号">
          <el-input placeholder="请输入电桩编号" v-model="queryInfo.pileCode" 
            @change="current">
          </el-input>
        </el-form-item>
        <el-form-item label="电桩类型：">
          <el-select v-model="queryInfo.pileType"  placeholder="请选择电桩类型" @change="pileTyping"
             filterable>
            <el-option v-for="item in pileType_list" :key="item.pileType" :label="item.label" :value="item.pileType">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通讯状态：">
          <el-select v-model="queryInfo.commStatus"  placeholder="请选择通讯状态" @change="tongxun"
             filterable>
            <el-option v-for="item in tongxun_list" :key="item.commStatus" :label="item.name" :value="item.commStatus">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作状态：" v-if="zhuangkuang == true">
          <el-select v-model="queryInfo.gunState"  placeholder="请选择工作状态" @change="leixing"
             filterable>
            <el-option v-for="item in gongzuo_list" :key="item.gunState" :label="item.name" :value="item.gunState">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="check_event">查询</el-button>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
          <el-button type="primary" @click="jizaigengduo">更多操作</el-button>
        </el-form-item>
        <el-form-item v-if="tianjiadianzhaung == true && zhuangkuang == true">
          <el-button type="success" icon="el-icon-plus" @click="showElePile">添加电桩</el-button>
        </el-form-item>
        <el-form-item v-if="shanchu == true && zhuangkuang == true">
          <el-button type="danger" icon="el-icon-delete" @click="removeById">批量删除</el-button>
        </el-form-item>
        <el-form-item v-if="piliangqianyi == true && zhuangkuang == true">
          <el-button @click="qianyi">批量迁移</el-button>
        </el-form-item>
        <el-form-item v-if="daoru == true && zhuangkuang == true">
          <el-button @click="importElePile">导入电桩</el-button>
        </el-form-item>
        <el-form-item v-if="daochu == true && zhuangkuang == true">
          <el-button @click="exportElePile">导出电桩</el-button>
        </el-form-item>
      </div>
      <!--</el-col> -->
    </el-form>
    <!-- table表格区域 -->
    <el-table class="table" :data="elePileList" border stripe @selection-change="handleSelectionChange"
      empty-text="暂无数据">
      <el-table-column align="center" type="selection" width="55"></el-table-column>
      <!-- <el-table-column align="center" label="ID" prop="id"></el-table-column> -->
      <el-table-column align="center" label="电桩编号" prop="pileCode" width="160"></el-table-column>
      <el-table-column align="center" label="枪号" prop="gunNumber" width="80">
        <template slot-scope="scope">
          <el-tag v-model="scope.row.gunNumber" v-if="scope.row.gunNumber == '1'">{{ scope.row.gunNumberName
            }}</el-tag>
          <el-tag type="success" v-model="scope.row.gunNumber" v-if="scope.row.gunNumber == '2'">{{
      scope.row.gunNumberName }}</el-tag>
          <el-tag type="warning" v-model="scope.row.gunNumber" v-if="scope.row.gunNumber == '3'">{{
      scope.row.gunNumberName }}</el-tag>
          <el-tag type="danger" v-model="scope.row.gunNumber" v-if="scope.row.gunNumber == '4'">{{
      scope.row.gunNumberName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="设备类型" prop="deviceTypeName" width="140"></el-table-column>
      <el-table-column align="center" label="电站名称" prop="stationName">
        <template slot-scope="scope">
          <div style="display: flex;justify-content: flex-start;">
            {{ scope.row.stationName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="工作状态" prop="runStateName" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.gunState == '0' ? 'info' :
      scope.row.gunState == '1' ? '' :
        scope.row.gunState == '2' ? 'success' :
          'warning'" v-model="scope.row.gunState" v-if="scope.row.gunState == '0' ||
      scope.row.gunState == '1' ||
      scope.row.gunState == '2' ||
      scope.row.gunState == '3' ||
      scope.row.gunState == '4' ||
      scope.row.gunState == '5' ||
      scope.row.gunState == '6' ||
      scope.row.gunState == '7' ||
      scope.row.gunState == '8' ||
      scope.row.gunState == '9'
      ">{{ scope.row.runStateName
            }}</el-tag>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="通讯状态" prop="communicationStatusName" width="100">
        <template slot-scope="scope">
          <el-tag type="info" v-model="scope.row.commStatus" v-if="scope.row.commStatus == '0'">{{
      scope.row.communicationStatusName }}</el-tag>
          <el-tag type="success" v-model="scope.row.commStatus" v-if="scope.row.commStatus == '1'">{{
      scope.row.communicationStatusName }}</el-tag>
          <el-tag v-model="scope.row.commStatus" v-if="scope.row.commStatus == '2'">{{
      scope.row.communicationStatusName
    }}</el-tag>
          <el-tag type="warning" v-model="scope.row.commStatus" v-if="scope.row.commStatus == '3'">{{
      scope.row.communicationStatusName }}</el-tag>
          <el-tag type="danger" v-model="scope.row.commStatus" v-if="scope.row.commStatus == '4'">{{
      scope.row.communicationStatusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="车连接" prop="carStateName" width="100">
        <template slot-scope="scope">
          <el-tag type="info" v-model="scope.row.gunToCarState" v-if="scope.row.gunToCarState == '0'">{{
      scope.row.carStateName }}</el-tag>
          <el-tag type="warning" v-model="scope.row.gunToCarState" v-if="scope.row.gunToCarState == '1'">{{
      scope.row.carStateName }}</el-tag>
          <el-tag type="success" v-model="scope.row.gunToCarState" v-if="scope.row.gunToCarState == '2'">{{
      scope.row.carStateName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="告警信息" prop="alarm"></el-table-column>
      <el-table-column align="center" label="SIM" prop="sim" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.sim">
            {{ scope.row.sim }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="pileState" width="120">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.pileState" :active-color="scope.row.pileState == true ? '#ff4949' : '#13ce66'"
            :inactive-color="scope.row.pileState == true ? '#ff4949' : '#13ce66'" @change="change_pileState(scope.row)"
            :disabled="!zhuangtai_quanxian"></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="状态" width="80" v-else>
          <template>
            <el-switch align="center" v-model="zhuangtai" inactive-text="禁用" style="padding-left: 25px;"
              disabled></el-switch>
          </template>
        </el-table-column> -->
      <el-table-column align="left" label="操作" width="360">
        <template slot-scope="scope">
          <!-- :disabled="dianzhuangrizhi_disabled" -->
          <el-button size="small" type="primary" icon="el-icon-edit" @click="showElePile(scope.row)"
            v-if="bianji">编辑</el-button>
          <el-button size="small" type="warning" @click="chongqi(scope.row)"
            v-if="chongqi_btn == true && scope.row.pileType == 0 || chongqi_btn == true && scope.row.pileType == 1"
            :disabled="scope.row.c_q_anniu">重启</el-button>
          <!-- <el-button size="small" type="danger" @click="removeById(scope.row)" v-if="caozuoquanxian">删除</el-button> -->
          <!-- <el-button size="small" disabled v-if="caozuoquanxian">推送</el-button> -->
          <el-button size="small" @click="shezhierweima(scope.row)">显示二维码</el-button>
          <el-button size="small" @click="dianzhuangrizhi(scope.row)"
            :disabled="scope.row.d_z_r_z_anniu">电桩日志</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加&编辑电桩的对话框 -->
    <el-dialog :title="title" :visible.sync="elePileVisible" width="35%" append-to-body :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="elePileForm" :rules="elePileRules" ref="elePileRef" label-width="95px">
        <el-form-item label="电桩编号" prop="pileCode">
          <el-input v-model="elePileForm.pileCode" :disabled="title === '编辑电桩'" placeholder="请输入电桩编号"
            style="width: 100%;" @change="changing"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="充电枪">
              <el-select v-model="elePileForm.gunNumber" :disabled="title === '编辑电桩'" placeholder="请选择充电枪" filterable>
                <el-option v-for="  item   in   gunOptions  " :key="item.gunNumber" :label="item.label"
                  :value="item.gunNumber">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="功率Kw" prop="pilePower">
              <el-select v-model="elePileForm.pilePower" placeholder="请选择电桩功率" filterable>
                <el-option v-for="  item   in   chapOptions  " :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属电站" prop="stationId">
              <el-select v-model="elePileForm.stationId" placeholder="请选择所属电站" @change="change_station" filterable>
                <el-option v-for=" item in station_list " :key="item.stationId" :label="item.stationName"
                  :value="item.stationId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="充电类型" prop="pileClassType">
              <el-select v-model="elePileForm.pileClassType" placeholder="请选择充电类型" filterable>
                <el-option v-for=" item   in   chatOptions  " :key="item.pileClassType" :label="item.label"
                  :value="item.pileClassType">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电桩类型" prop="pileType">
              <el-select v-model="elePileForm.pileType" placeholder="请选择电桩类型" filterable>
                <el-option v-for="item   in   devtOptions  " :key="item.pileType" :label="item.label"
                  :value="item.pileType">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通讯方式" prop="netType">
              <el-select v-model="elePileForm.netType" placeholder="请选择通讯方式" filterable>
                <el-option v-for="  item   in   comTypeOptions  " :key="item.netType" :label="item.label"
                  :value="item.netType">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="限定充电" prop="limitType">
              <el-select v-model="elePileForm.limitType" placeholder="请选择通讯方式" filterable>
                <el-option v-for="  item   in   limitTypeOptions  " :key="item.limitType" :label="item.label"
                  :value="item.limitType">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备简述" prop="pileSketch">
              <el-input v-model="elePileForm.pileSketch" placeholder="请输入设备简述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx1">取 消</el-button>
        <el-button type="primary" @click="editElePile">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入电桩列表 -->
    <el-dialog title="导入" :visible.sync="importDialogVisible" width="660px" :close-on-click-modal="false"
      :before-close="onClose" @closed="onAfterClose">
      <div v-loading="loading" class="dialog-content">
        <el-form ref="formRef" :model="formData" label-width="80px">
          <el-form-item label="选择文件" prop="fileList">
            <el-upload ref="upload" action="#" :accept="extAcceptStr" :auto-upload="false" :limit="limit"
              :file-list="formData.fileList" :on-change="onFileChange" :on-remove="onFileMove"
              :on-exceed="onFileExceed">
              <div class="select-file">
                <p class="select-p">请选择上传文件</p>
                <el-button size="small" type="primary">选取文件</el-button>
              </div>
              <div slot="tip" class="el-upload__tip">{{ `支持${extAcceptStr} 格式的文件，大小不能超过10M` }}</div>
            </el-upload>
            <!-- <el-button type="text" @click="onDownload">模板下载</el-button> -->
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button type="primary" :loading="!!loading" :disabled="!formData.fileList.length > 0" @click="onSubmit">导入
        </el-button>
        <el-button @click="importDialogVisible = false">取消</el-button>
      </template>
    </el-dialog>
    <!-- 分页导航区域 -->
    <el-pagination @size-change="changesize" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <el-dialog title="批量迁移电桩" :visible.sync="qianyidizhi" width="30%">
      <el-select v-model="qianyidianzhuang" clearable placeholder="请选择迁往电站" @change="qianyi_station" filterable>
        <el-option v-for="item in station_list_listall" :key="item.stationId" :label="item.stationName"
          :value="item.stationId">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qinyidizhi3">取 消</el-button>
        <el-button type="primary" @click="qinyidizhi2">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="电桩日志" :visible.sync="rizhi" width="50%" :close-on-click-modal="false" :before-close="quxiao"
      @closed="quxiao">
      <div class="table">
        <el-table :data="tableData" stripe style="width: 100%" empty-text="暂无数据">
          <el-table-column prop="pileIpAddr" label="登录IP">
          </el-table-column>
          <el-table-column prop="threadId" label="连接线程">
          </el-table-column>
          <el-table-column prop="pileCode" label="登陆桩号">
          </el-table-column>
          <el-table-column prop="loginTime" label="上线时间">
          </el-table-column>
          <el-table-column prop="loginOutTime" label="离线时间">
            <template slot-scope="scope">
              <span v-if="scope.row.loginOutTime != null">{{ scope.row.loginOutTime }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="loginState" label="登录状态">
            <template slot-scope="scope">
              <el-tag type="info" v-model="scope.row.loginState" v-if="scope.row.loginState == '1'">离线</el-tag>
              <el-tag type="success" v-model="scope.row.loginState" v-if="scope.row.loginState == '0'">成功</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handlesize" @current-change="handleCurrent" :current-page="rizi.page"
          :page-sizes="[10, 20, 50, 100]" :page-size="rizi.limit" layout="total, sizes, prev, pager, next, jumper"
          :total="total_all" background>
        </el-pagination>
      </div>
    </el-dialog>
  </el-card>
  <!-- :page-size="pageSize_all" -->
</template>

<script>
import VueQr from 'vue-qr';
import { saveBlobToFile } from '@/utils/saveBlobToFile';
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'elePileWorld',
  props: {
    loading: Boolean,
    limit: { //限制文件上传个数
      type: Number,
      default: 1
    }
  },
  components: {
    VueQr
  },
  data() {
    // 电桩编号的校验规则
    const checkpileCode = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9]+$/
      if (reg.test(value)) {
        return callback()
      }
      callback(new Error('电桩编号由数字、字母或者或者其中一种组成'))
    }
    return {
      autosize: false,
      yincang: true,
      daochu: 162,
      zhuangkuang: false,
      bianji: false,
      chongqi_btn: false,
      tianjiadianzhaung: false,
      shanchu: false,
      piliangqianyi: false,
      daoru: false,
      pileType_list: [
        {
          pileType: 0,
          label: '锐创直流桩'
        },
        {
          pileType: 1,
          label: '锐创交流桩'
        },
        {
          pileType: 2,
          label: '无疆(二代)交流桩'
        },
        {
          pileType: 3,
          label: '光法(一代)直流桩'
        },
        {
          pileType: 4,
          label: '光法(一代)交流桩'
        },
        {
          pileType: 5,
          label: '锐创低速桩'
        }
      ],
      tongxun_list: [
        {
          commStatus: 0,
          name: '已下线'
        }, {
          commStatus: 1,
          name: '很好'
        },
        {
          commStatus: 2,
          name: '正常'

        }, {
          commStatus: 3,
          name: '一般'
        }, {
          commStatus: 4,
          name: '很差'
        }],
      gongzuo_list: [
        {
          gunState: 0,
          name: '空闲中'
        }, {
          gunState: 1,
          name: '准备开始充电'
        },
        {
          gunState: 2,
          name: '充电进行中'

        }, {
          gunState: 3,
          name: '充电结束'
        }, {
          gunState: 4,
          name: '启动失败'
        },
        {
          gunState: 5,
          name: '预约状态'
        }, {
          gunState: 6,
          name: '系统故障'
        },
        {
          gunState: 7,
          name: '升级中'
        }
      ],
      limitTypeOptions: [{
        limitType: 0,
        label: '全部允许'
      }, {
        limitType: 1,
        label: '禁止小程序用户'
      }, {
        limitType: 2,
        label: '禁止电卡用户'
      }, {
        limitType: 4,
        label: '禁止小程序和电卡用户'
      },
      {
        limitType: 3,
        label: '禁止Vin用户'
      },
      {
        limitType: 5,
        label: '禁止小程序和Vin用户'
      },
      {
        limitType: 6,
        label: '禁止电卡和Vin用户'
      }],
      zhuangtai: false,
      qianghao: '',
      dianzhuanghao: '',
      url: '',
      codeText: "",
      dialogVisible: false,
      dianzhuangrizhi_disabled: false,
      ids4: 0,
      xuanzedianzhan: null,
      code: null,
      pageSize_all: 0,
      total_all: 100,
      currentPage3: 0,
      tableData: [],
      rizhi: false,
      newstationIds: null,
      qianyidizhi: false,
      qianyidianzhuang: '',
      // # config
      extAccept: ["xls", "xlsx"],
      // # state
      importDialogVisible: false,
      // # data
      formData: {
        fileList: []
      },
      // 查询所有电桩列表的参数
      queryInfo: {
        pileCode: null,
        stationIds: null,
        gunState: null,
        commStatus: null,
        page: 1,
        limit: 10,
        pileType: null,
        stationMode: '0,1',
        roleId: window.sessionStorage.getItem('roleId')
      },
      rizi: {
        pileCode: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 10
      },
      total: 0,
      // 枪号数据
      gunOptions: [
        {
          gunNumber: 1,
          label: '单枪'
        },
        {
          gunNumber: 2,
          label: '双枪'
        }
      ],
      // 功率数据
      chapOptions: [
        {
          value: 0,
          label: 0
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 14,
          label: 14
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 40,
          label: 40
        },
        {
          value: 60,
          label: 60
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 120,
          label: 120
        },
        {
          value: 140,
          label: 140
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 180,
          label: 180
        },
        {
          value: 200,
          label: 200
        },
        {
          value: 240,
          label: 240
        },
        {
          value: 260,
          label: 260
        },
        {
          value: 300,
          label: 300
        },
        {
          value: 320,
          label: 320
        },
        {
          value: 360,
          label: 360
        },
        {
          value: 400,
          label: 400
        },
        {
          value: 480,
          label: 480
        },
        {
          value: 600,
          label: 600
        }
      ],
      // 充电类型
      chatOptions: [
        {
          pileClassType: '0',
          label: '慢充'
        },
        {
          pileClassType: '1',
          label: '快充'
        },
        {
          pileClassType: '2',
          label: '低速'
        }
      ],
      // 电桩类型
      devtOptions: [
        {
          pileType: 0,
          label: '锐创直流桩'
        },
        {
          pileType: 1,
          label: '锐创交流桩'
        },
        {
          pileType: 2,
          label: '无疆(二代)交流桩'
        },
        {
          pileType: 3,
          label: '光法(一代)直流桩'
        },
        {
          pileType: 4,
          label: '光法(一代)交流桩'
        },
        {
          pileType: 5,
          label: '锐创低速桩'
        }
      ],
      stationName: [],
      comTypeOptions: [
        {
          label: '4G路由',
          netType: 0
        }, {
          label: '以太网',
          netType: 1
        }, {
          label: 'DTU',
          netType: 2
        }
      ],
      // 车连接状态
      carOptions: [
        {
          label: '断开',
          value: 0
        }, {
          label: '半连接',
          value: 1
        }, {
          label: '连接',
          value: 2
        }
      ],

      // 电桩列表及参数
      elePileList: [],
      page_num: 0,
      page_num1: 0,
      elePileForm: {
        id: null,
        pileCode: null,
        stationId: null,
        gunNumber: null,
        pilePower: null,
        pileClassType: null,
        pileType: null,
        netType: null,
        pileSketch: null,
        pileState: null,
        limitType: null,
        pileGl: ''
      },
      elePileRules: {
        pileCode: [
          { required: true, message: '电桩编号不能为空', trigger: 'blur' },
          { validator: checkpileCode, trigger: 'blur' }
        ],
        stationId: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        gunNumber: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pilePower: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pileClassType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pileType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        netType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pileSketch: [
          { required: false, message: '请至少选择一项', trigger: 'change' }
        ],
        limitType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ]
      },
      ids1: 0,
      ids2: 0,
      ids3: 0,
      elePileVisible: false,
      title: '',
      value: '',
      shows: false,
      pileNumbers: null,
      ids: '',
      powerStation: [],
      canshu: {},
      checked_all: '',
      station_list: [],
      station_list_listall: [],
      station_list_all: [],
      caozuoquanxian: false,
      gunState: null,
      commStatus: null,
      zhuangtai_quanxian: false,
    }
  },
  created() {
    const that = this;
    caozuo(170).then(res => {
      that.zhuangtai_quanxian = res;
    });
    caozuo(156).then(res => {
      that.bianji = res;
    });
    caozuo(162).then(res => {
      that.daochu = res;
    });
    caozuo(95).then(res => {
      that.chongqi_btn = res;
    });
    caozuo(159).then(res => {
      that.tianjiadianzhaung = res;
    });
    caozuo(157).then(res => {
      that.shanchu = res;
    });
    caozuo(158).then(res => {
      that.daoru = res;
    });
    caozuo(160).then(res => {
      that.piliangqianyi = res;
    });
    that.getPowerStationList();
  },
  computed: {
    extAcceptStr() {
      return this.extAccept.map(ext => `.${ext}`).join(",")
    },
  },
  watch: {
    'queryInfo.stationIds': {
      handler(newValue) {
        if (newValue == null) {
          this.queryInfo.stationIds = window.sessionStorage.getItem('stationIds');
        } else {
          this.queryInfo.stationIds = newValue
        }
      }
    }
  },
  methods: {
    changing() {
      const that = this;
      that.$http.get(`pileInfo/getPileInfoCodeById/${that.elePileForm.pileCode}`).then(res => {
        if (res.data.code !== 0) return;
        that.$message.error('电桩编号已存在');
        that.elePileForm.pileCode = '';
      })
    },
    // 编辑的取消按钮；
    qx1() {
      this.$refs.elePileRef.resetFields();
      this.elePileVisible = false;
      this.elePileForm = {};
      this.elePileList.forEach(item => {
        if (item.id == this.ids1) {
          item.b_j_anniu = false;
        }
      })

    },
    handleClose() {
      this.dialogVisible = false;
      this.url = '';
      this.dianzhuanghao = '';
    },
    zhanshi() {
      this.yincang = !this.yincang
    },
    // 重启
    async chongqi(e) {
      this.ids2 = e.id;
      const confirmResult = await this.$confirm('此操作将重启, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        e.c_q_anniu = false;
        this.$message.info('已取消重启！')
      } else {
        this.$http.get(`/pileInfo/Restart/${e.pileCode}/${Number(window.sessionStorage.getItem('adminId'))}`).then(res => {
          if (res.data.code == 0) {
            e.c_q_anniu = true;
            this.$message.success(res.data.msg)
          } else {
            e.c_q_anniu = false;
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    // 加载更多
    jizaigengduo() {
      this.zhuangkuang = !this.zhuangkuang;
    },
    // 改变电桩状态
    async change_pileState(row) {
      const { data: res } = await this.$http.post('pileInfo/updateStateByPileCode', {
        pileCode: row.pileCode,
        pileState: row.pileState
      })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('编辑电桩成功！')
      this.check_event();
      this.elePileVisible = false
    },
    // 重置搜索条件
    chongzhi() {
      this.queryInfo = {
        pileCode: null,
        stationIds: null,
        gunState: null,
        commStatus: null,
        page: 1,
        limit: 10,
        pileType: null,
        stationMode: '0,1',
        roleId: window.sessionStorage.getItem('roleId'),
      };
      this.xuanzedianzhan = null;
      this.getPowerStationList();
    },
    // 获取电站列表
    async getPowerStationList() {
      this.elePileList = [];
      this.queryInfo.stationIds = this.queryInfo.stationIds == null ? window.sessionStorage.getItem('stationIds') : this.queryInfo.stationIds;
      this.$http.get('pileInfo/getAllPileInfo', {
        params: {
          pileCode: this.queryInfo.pileCode,
          stationIds: this.queryInfo.stationIds,
          gunState: this.queryInfo.gunState,
          commStatus: this.queryInfo.commStatus,
          page: this.queryInfo.page,
          limit: this.queryInfo.limit,
          pileType: this.queryInfo.pileType == null ? "0,1,2,3,4,5" : this.queryInfo.pileType,
          roleId: this.queryInfo.roleId,
        }
      }).then(res => {
        if (res.data.code == 0) {
          this.elePileList = res.data.data;
          this.elePileList.forEach(item => {
            item.b_j_anniu = false; item.c_q_anniu = false; item.d_z_r_z_anniu = false
          });
          this.total = res.data.count
        }
      })
      let a = null;
      this.station_list_all = [];
      this.station_list = [];
      await this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1'
        }
      }).then(resb => {
        resb.data.data.forEach((item) => {
          this.station_list.push({
            stationId: item.id,
            stationName: item.stationName
          });
          this.station_list_all.push({
            stationId: item.id,
            stationName: item.stationName
          });
        })
      })

    },
    // 搜索
    sousuo(e) {
      this.queryInfo.page = 1;
      this.queryInfo.stationIds = e;
    },
    current() {
      this.queryInfo.page = 1;
    },
    // 工作状态
    leixing(e) {
      this.queryInfo.page = 1;
      this.queryInfo.gunState = e;
    },
    // 电桩类型
    pileTyping(e) {
      this.queryInfo.page = 1;
      this.queryInfo.pileType = e;
      this.getPowerStationList()
    },
    // 通讯状态
    tongxun(e) {
      this.queryInfo.page = 1;
      this.queryInfo.commStatus = e;
    },
    // 复选框选择事件
    async check_event() {
      this.elePileList = [];
      this.queryInfo.stationIds == null ? window.sessionStorage.getItem('stationIds') : this.xuanzedianzhan;
      this.getPowerStationList()
    },
    // 改变电站
    change_station(e) {
      this.queryInfo.stationIds = e;
    },
    // 设置二维码
    shezhierweima(row) {
      this.ids3 = row.id;
      this.dialogVisible = true;
      if (row.qrCodeType == 0) {
        this.url = row.qrCode;
      } else {
        this.url = row.publicQrCode;
      };
      this.dianzhuanghao = row.pileCode + row.gunNumberName;
    },
    // 改变页码
    handleCurrent(newPage) {
      this.rizi.page = newPage
      // 页码跳跃
      this.public_function()
    },
    // 改变数据条数
    handlesize(newSize) {
      this.rizi.limit = newSize;
      // 页码跳跃
      this.public_function()
    },
    quxiao() {
      this.rizhi = false;
      this.elePileList.forEach(item => {
        if (item.id == this.ids4) {
          item.d_z_r_z_anniu = false
        }
      })
    },
    public_function() {
      this.rizi.pileCode = this.code;
      this.$http.get('pile-login-log/getAllPileLoginLog', {
        params: this.rizi
      }).then(resb => {
        this.tableData = resb.data.data;
        if (this.tableData.length < 1) {
          this.rizhi = false;
          this.dianzhuangrizhi_disabled = true;
          this.$message.error('暂无电桩日志');
        } else {
          this.dianzhuangrizhi_disabled = false;
          this.rizhi = true;
          this.total_all = resb.data.count;
          // if (val == 1) {
          //   this.total_all = resb.data.count;
          //   this.pageSize_all = Math.ceil(this.total_all / 5);
          //   console.log(this.pageSize_all, 'this.pageSize_all');
          // }
        }
      })
    },
    async dianzhuangrizhi(row) {
      this.ids4 = row.id;
      if (this.currentPage3 == 0) {
        this.currentPage3 = 1
      };
      this.code = row.pileCode;
      this.public_function()
      // this.public_function(this.currentPage3)
    },
    qianyi_station(e) {
      this.newstationIds = e;
    },
    qinyidizhi2() {
      this.$http.post('/pileInfo/updPileInfoStationId?ids=' + this.ids + '&stationId=' + this.newstationIds + '&adminId=' + Number(window.sessionStorage.getItem('adminId'))).then(res => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.error(res.data.msg);
        }
        this.qianyidizhi = false;
        this.getPowerStationList();
      })
    },
    qinyidizhi3() {
      this.qianyidianzhuang = '';
      this.qianyidizhi = false;
    },
    qianyi() {
      const that = this;
      that.station_list_listall=[];
      if (that.queryInfo.pileType == null || that.queryInfo.pileType == undefined || that.queryInfo.pileType.length == 0 || that.pileNumbers == '') return that.$message.error(`请先选择你要迁移的
      ${that.pileNumbers == '' && that.queryInfo.pileType == '' ? '电桩、设备类型' : that.pileNumbers == '' ? '电桩' : '设备类型'}`);
      console.log(that.queryInfo.pileType, '1064');
      this.qianyidizhi = true;
      that.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'), stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: that.queryInfo.pileType == 6 ? '2' : '0,1'
        }
      }).then(res => {
        if (res.data.code !== 0) return;
        res.data.data.forEach(item => {
          that.station_list_listall.push({
            stationId: item.id,
            stationName: item.stationName
          })
        })
      })
    },
    importElePile() {
      this.importDialogVisible = true
    },
    onClose() {
      this.importDialogVisible = false
    },
    onAfterClose() {
      this.formData = {
        fileList: []
      }
    },
    // # upload
    // 校验
    validateFile(file) {
      if (!file) return "至少添加一个文件！"
      const ext = (file.name || "").split(".")[1]
      if (!this.extAccept.includes(ext))
        return `仅支持${this.extAcceptStr}格式的文件`
      return ""
    },
    // 选择文件的改变事件
    onFileChange(file, fileList) {
      const errMsg = this.validateFile(file)
      if (errMsg) {
        this.$message.error(errMsg)
        this.formData.fileList.splice(0, 1)
      } else {
        this.formData.fileList = fileList
      }
    },
    onFileMove(file, fileList) {
      this.formData.fileList = fileList
    },
    onFileExceed() {
      this.$message.error(`仅允许上传 ${this.limit} 个文件！`)
    },
    // # emit
    // 模板下载
    // async onDownload() {
    //   const { data: res } = await this.$http.get('chargingPile/exportExcel/' + 2940, { responseType: 'blob' })
    //   saveBlobToFile(res, '电桩模板')
    //   if (res.data.code != 0) {
    //     this.$message.error(res.msg)
    //   }
    //   this.$message.success(res.msg)
    // },
    // 导入
    async onSubmit() {
      var formData = new FormData()
      for (const file of this.formData.fileList) {//多个文件全部都放到files
        if (file.raw) {
          formData.append('file', file.raw);
          formData.append('adminId', window.sessionStorage.getItem('adminId') * 1)
        }
      }
      const { data: res } = await this.$http.post('pileInfo/importPileInfo', formData)
      this.importDialogVisible = false
      if (res.data.code != 0) {
        this.$message.error(res.msg)
        this.check_event()
      } else {
        this.$message.success(res.msg)
      }
    },
    changesize(newSize) {
      this.queryInfo.limit = newSize;
      this.getPowerStationList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getPowerStationList()
    },
    // 展示添加&编辑电桩对话框
    async showElePile(row) {
      this.elePileVisible = true;
      this.ids1 = row.id;
      if (row.id === undefined || row.id === null || row.id === '') {
        this.title = '添加电桩';
        this.elePileForm.limitType = this.limitTypeOptions[0].limitType;
        this.gunOptions = [
          {
            gunNumber: 1,
            label: '单枪'
          },
          {
            gunNumber: 2,
            label: '双枪'
          }
        ]
      } else {
        this.title = '编辑电桩'
        this.shows = true;
        this.gunOptions = [
          {
            gunNumber: 1,
            label: 'A枪'
          },
          {
            gunNumber: 2,
            label: 'B枪'
          }
        ];
        const { data: resb } = await this.$http.get('pileInfo/getPileInfoById/' + row.id)
        if (resb.code !== 0) {
          row.b_j_anniu = false;
          this.$message.error(resb.msg)
        } else {
          row.b_j_anniu = true;
          this.elePileForm = resb.data;
          if (resb.data.stationName == null) {
            this.elePileForm.stationName = '暂无电站名称'
          } else {
            this.elePileForm.stationName = resb.data.stationName
          }
        }
      }
    },
    // 添加&编辑电桩提交事件
    editElePile() {
      this.$refs.elePileRef.validate(async valid => {
        if (!valid) return;
        if(this.elePileForm.pileCode=='')return this.$message.error('电桩编号为必传项');
        this.elePileForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (this.title === '添加电桩') {
          const { data: res } = await this.$http.post('pileInfo/addPileInfo', this.elePileForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('添加电桩成功！')
          this.check_event()
          this.elePileVisible = false
        } else {
          this.elePileForm.state === true ? this.elePileForm.state === 0 : this.elePileForm.state === 1
          const { data: res } = await this.$http.post('pileInfo/updPileInfo', this.elePileForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('编辑电桩成功！')
          this.check_event()
          this.elePileVisible = false
        }
      })
    },
    elePileDialogClosed() {
      this.shows = false
      this.$refs.elePileRef.resetFields()
    },
    // 删除电桩
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该电桩(*包含该桩号对应的所有枪号), 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.pileCode === null || row.pileCode === '' || row.pileCode === undefined) {
        const { data: res } = await this.$http.post('pileInfo/delPileInfoById/' + this.pileNumbers + '/' + Number(window.sessionStorage.getItem('adminId')))
        if (res.code !== 0) {
          return this.$message.error('删除电桩失败！')
        }
        this.$message.success('删除电桩成功！')
        this.check_event()
      } else {
        const { data: res } = await this.$http.post('pileInfo/delPileInfoById/' + row.pileCode + '/' + Number(window.sessionStorage.getItem('adminId')))
        if (res.code !== 0) {
          return this.$message.error('删除电桩失败！')
        }
        this.$message.success('删除电桩成功！')
        this.check_event()
      }
    },
    // 批量删除的复选框选择事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        this.pileNumbers = ''
        console.log(value)
        const newValue = []
        const newPileNumbers = []
        value.forEach(item => {
          newValue.push(item.id)
          newPileNumbers.push(item.pileCode)
        })

        Array.from(new Set(newValue)).forEach(item => {
          this.ids += item + ','
        })
        Array.from(new Set(newPileNumbers)).forEach(item => {
          this.pileNumbers += item + ','
        })
        // substring(index, array/string.length - n)
        //  n代表去掉后几位，index 从下标为index开始
        this.ids = this.ids.substring(0, this.ids.length - 1)
        console.log(this.ids, 'ids');
        this.pileNumbers = this.pileNumbers.substring(0, this.pileNumbers.length - 1)
        new Set()
        // 方法返回是一个类数组，需要结合 ...运算符或者Array.from()，转成真实数组
        // console.log([...new Set(newValue)]  === Array.from(new Set(newValue)))
      }
    },
    // 导出
    async exportElePile() {
      if (this.ids === '' || this.ids === null || this.ids === undefined) {
        this.$alert('电桩选择不能为空', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: action => {
            this.$message({
              type: 'error',
              message: '请选择要导出的电桩！'
            })
          }
        })
      } else {
        const { data: res } = await this.$http.get('pileInfo/exportExcel/' + this.ids + '/' + Number(window.sessionStorage.getItem('adminId')), { responseType: 'blob' })
        saveBlobToFile(res, '电桩列表')
        if (res) {
          this.$message.success('文件导出成功')
        } else {
          this.$message.error('文件导出失败')
        }
      }
    }
  }
}
</script>