<template>
  <div>
    <!-- 卡片视图区域 运营人员-->
    <el-card>
      <!-- 添加角色按钮区域  -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">车主编号:</span>
          <el-input style="flex:7" placeholder="请输入车主编号:" clearable v-model="queryInfo.userNumber" @clear="getRolesList"
            @change="current">
          </el-input>
        </el-col>
        <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">优惠类型:</span>
          <el-select style="flex:7" v-model="queryInfo.preferentialType" placeholder="请选择优惠类型" filterable clearable
            @clear="getRolesList" @change="current">
            <el-option v-for="item in zhuangTypelist" :key="item.preferentialType" :label="item.stationName"
              :value="item.preferentialType">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getRolesList">查询</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
      </el-row>
      <!-- 角色列表区域 -->
      <el-table :data="rolesList" border @selection-change="handleSelectionChange" empty-text="暂无数据">
        <!-- 索引列 -->
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="6">
                  <el-form-item label="适用场站：">
                    <span v-if="scope.row.stationName !== null">{{ scope.row.stationName }}</span>
                    <span v-else>--</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="备注：" prop="des">
                    <span v-if="scope.row.des != null">
                      {{ scope.row.des }}
                    </span>
                    <span v-else> 暂无</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55"></el-table-column>
        <!-- <el-table-column align="center" label="序列" prop="id"></el-table-column> -->
        <el-table-column align="center" label="车主编号" prop="userNumber"></el-table-column>
        <el-table-column align="center" label="价格" prop="money">
          <template slot-scope="scope">
            <span>
              {{ scope.row.money }}元
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="限定电量" prop="chargenum">
          <template slot-scope="scope">
            <span>
              {{ scope.row.chargenum }}度
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="已用电量" prop="usecharge">
          <template slot-scope="scope">
            <span>
              {{ scope.row.usecharge }}度
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="限定天数" prop="dayNums">
          <template slot-scope="scope">
            <span>
              {{ scope.row.dayNums }}天
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="最低消费金额" prop="fullAmount">
          <template slot-scope="scope">
            <span v-if="scope.row.preferentialType == 4">
              {{ scope.row.fullAmount }}元
            </span>
            <span v-else>
              --
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="生效时间" prop="startTime"></el-table-column>
        <el-table-column align="center" label="结束时间" prop="endTime"></el-table-column>
        <!-- <el-table-column align="center" label="适用场站" prop="stationIds"></el-table-column> -->
        <el-table-column align="center" label="优惠类型" prop="preferentialType">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.state == 1 ? 'default' : scope.row.state == 2 ? 'success' : scope.row.state == 3 ? 'warning' : 'danger'">
              {{
        scope.row.preferentialType == 1 ? '服务费' : scope.row.preferentialType == 2 ? '电费' :
          scope.row.preferentialType == 3 ? '总费用' : '新人优惠券'
      }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="state">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state == 0 ? 'default' : scope.row.state == 1 ? 'danger' : 'warning'">
              {{ scope.row.state == 0 ? '生效中' : scope.row.state == 1 ? '已失效' : '未生效' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="购买时间" prop="createTime"></el-table-column>
        <el-table-column align="left" label="操作" width="300px" v-if="caozuoquanxian">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeRoleById(scope.row)"
              :disabled="scope.row.disabl">失效</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'roleWorld',
  data() {
    return {
      caozuoquanxian: false,
      newarr: [],
      quxiaoarr: [],
      arr: [],
      hr: '',
      fen: false,
      flag: false,
      del_btn: true,
      user_title: '',
      dialoag: false,
      queryInfo: {
        preferentialType: '',
        billCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId')
      },
      total: null,
      title: '',
      editForm: {
        rolename: null,
        // roleType: null,
        roleTypeName: null,
        des: null,
        id: null
      },
      editFormRules: {
        rolename: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
        // ,
        // roleTypeName: [
        //   { required: true, message: '角色类型不能为空', trigger: 'blur' }
        // ]
      },
      shanchuarr: [],
      editDialogVisible: false,
      // 所有角色列表数据
      rolesList: [],
      // 角色类型数据
      // roleTypeList: [
      //   { roleType: 0, roleTypeName: '管理员' },
      //   { roleType: 1, roleTypeName: '商户' },
      //   { roleType: 2, roleTypeName: '运营商(编辑)' },
      //   { roleType: 3, roleTypeName: '运营商(只读)' },
      //   { roleType: 4, roleTypeName: '发卡' },
      //   { roleType: 5, roleTypeName: '保留' },
      //   { roleType: 6, roleTypeName: '保留' },
      //   { roleType: 7, roleTypeName: '保留' },
      //   { roleType: 9996, roleTypeName: '销售' },
      //   { roleType: 9997, roleTypeName: '平台财务' },
      //   { roleType: 9998, roleTypeName: '普通管理员' },
      //   { roleType: 9999, roleTypeName: '售后' }
      // ],
      // 控制分配权限对话框的显示与隐藏
      powerDialogVisible: false,
      // 所有权限数据
      powerList: [],
      // 角色对应权限数据
      roleLogList: [],
      // 树形控件的属性绑定对象
      treeProps: {
        label: 'menuname',
        children: 'childList'
      },
      // 默认选中节点Id值的数组
      defKeys: [],
      ids: null,
      sttt: null,
      aaa: [],
      ntr: [],
      shuju: {
        name: null,
        page: 1,
        limit: 8
      },
      zhuangTypelist: [
        {
          preferentialType: 1,
          stationName: '服务费'
        },
        {
          preferentialType: 2,
          stationName: '电费'
        },
        {
          preferentialType: 3,
          stationName: '总费用'
        },
        {
          preferentialType: 4,
          stationName: '新人送抵扣券'
        }
      ]
    }
  },
  watch: {
    defKeys: {
      handler(newval) {
        this.defKeys = newval
      }
    }
  },
  created() {
    caozuo(148).then(res => {
      this.caozuoquanxian = res;
    });
    this.getRolesList();
    if (this.ids != null) {
      this.del_btn = !this.del_btn
    } else {
      this.del_btn = true
    };
    this.default_value();
  },
  methods: {
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        preferentialType: '',
        billCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId') * 1
      };
      this.getRolesList()
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getRolesList();
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getRolesList();
    },

    setPowerDialogClosed() {
      this.dialoag = false;
    },
    checkCange(data, checked) {
      this.quxiaoarr = this.defKeys;
      if (checked == true) {
        this.arr.push(data.id);
      } else {
        this.shanchuarr.push(data.id);
      };
      if (this.shanchuarr.length > 0) {
        this.arr = this.quxiaoarr.filter(item => !this.shanchuarr.includes(item))
      } else {
        let ntr = this.arr;
        this.arr = Array.from(new Set(this.quxiaoarr.concat(ntr)));
      };
      this.hr = this.arr.map(item => item).join(',');
    },
    async default_value() {
      // 获取所有权限的数据
      const { data: res } = await this.$http.get('adminMenu/getAllAdminMenu', {
        params: this.shuju
      })
      this.powerList = res.data;
      this.powerList.forEach(item => {
        item.childList.forEach(ite => {
          ite.childList = ite.childList3
        })
      });
    },
    // 获取所有角色的列表
    async getRolesList() {
      const { data: res } = await this.$http.get('coupons/getAllCoupons', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.rolesList = res.data;
      this.total = res.count;

      this.rolesList.forEach(item => {
        if (item.state !== 1) {
          item.disabl = false;
        } else {
          item.disabl = true;
        }
        item.usecharge = (item.usecharge / 100).toFixed(2);
        item.chargenum = (item.chargenum / 100).toFixed(2);
        item.money = (item.money / 100).toFixed(2)
      });
    },
    // 复选框的改变事件
    handleSelectionChange(value) {
      this.ids = '';
      if (value.length > 0) {
        if (value.length > 1) {
          this.del_btn = false;
          value.forEach(item => {
            this.ids += item.id + ','
          })
          this.ids = this.ids.substring(0, this.ids.length - 1)
        } else {
          this.ids = value[0].id
        }
      } else {
        this.del_btn = true;
      }
    },
    // 根据id删除对应的角色

    // 失效
    async removeRoleById(row) {
      const confirmResult = await this.$confirm('此操作将导致该优惠彻底失效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        row.disabl = false;
        this.$message.info('已取消失效操作')
      } else {
        if (row.state == '0') {
          row.state = '1'
        } else if (row.state == '2') {
          row.state = '0'
        };
        const { data: res } = await this.$http.post('coupons/updCoupons', {
          id: row.id,
          state: row.state,
          adminId: window.sessionStorage.getItem('adminId') * 1
        })
        if (res.code == 0) {
          this.$message.success(res.msg);
          row.state = res.data.state;
          if (row.state == 1) {
            row.disabl = true;
            this.getRolesList()
          } else {
            row.disabl = false;
            this.getRolesList()
          }
        } else {
          this.$message.error(res.msg)
        }
      }

    },
  }
}
</script>
