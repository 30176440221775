<template>
  <div>
    <!-- 卡片视图区域 登陆日志-->
    <el-card>
      <!--  -->
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input placeholder="请输入用户名称" clearable v-model="queryInfo.adminName" @change="current" @clear="getLogList"
            :value="queryInfo.adminName">
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-date-picker v-model="value" type="daterange" @blur="pickBlur" :picker-options="pickerOptions"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeTime">
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getLogList">查询</el-button>
        </el-col>
        <el-col :span="1">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <!-- <el-col :span="1" style="margin-left: 30px;" v-if="caozuoquanxian">
            <el-button @click="removeById">批量删除</el-button>
          </el-col> -->
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="logList" border stripe empty-text="暂无数据">
        <el-table-column align="center" label="用户" prop="adminName" width="180">
          <template scope="scope">
            <span v-if="scope.row.adminName == null">--</span>
            <span v-else>{{ scope.row.adminName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="登陆IP" prop="ip" width="180"></el-table-column>
        <el-table-column align="center" label="登陆地址" prop="address"></el-table-column>
        <el-table-column align="center" label="登陆系统" prop="os" width="160"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="100px">
          <template slot-scope="scope">
            {{ scope.row.createTime | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="130px" v-if="caozuoquanxian">
          <template slot-scope="scope">
            <el-button size="small" type="danger" icon="el-icon-delete" @click="removeById(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'logWorld',
  data() {
    return {
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.value = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      caozuoquanxian: false,
      // 查询参数对象
      queryInfo: {
        adminName: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 10
      },
      // 商品列表
      logList: [],
      // 总数据条数
      total: 0,
      value: ['', ''],
      ids: ''
    }
  },
  created() {
    caozuo(127).then(res => {
      this.caozuoquanxian = res;
    });
    this.getLogList()
  },
  methods: {
    pickBlur() {
      this.pickerMinDate = ''
    },
    current() {
      this.queryInfo.page = 1;
    },
    changeTime(value) {
      this.queryInfo.page = 1;
      if (value) {
        this.queryInfo.startTime = value[0]
        this.queryInfo.endTime = value[1]
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        this.queryInfo.startTime = null;
        this.queryInfo.endTime = null;
        this.pickerMinDate = '';
        this.getLogList()
      }
    },
    chongzhi() {
      this.queryInfo = {
        adminName: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 8
      };
      this.value = [];
      this.pickerMinDate = '';
      this.getLogList()
    },
    // 根据分页获取对应的日志列表
    async getLogList() {
      const { data: res } = await this.$http.get('loginLog/getAllAdminLoginLog', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.logList = res.data
      this.total = res.count
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getLogList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getLogList()
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    getDate() {
      const start = new Date()
      this.value[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.value[1] = this.formatDate(start.getTime(), 'end')
    },
    // 删除日志
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('loginLog/delAdminLoginLog/' + this.ids)
        if (res.code !== 0) {
          return this.$message.error('删除日志失败！')
        }
        this.$message.success('删除日志成功！')
        this.getLogList()
      } else {
        const { data: res } = await this.$http.post('loginLog/delAdminLoginLog/' + row.id.toString())
        if (res.code !== 0) {
          return this.$message.error('删除日志失败！')
        }
        this.$message.success('删除日志成功！')
        this.getLogList()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>