<template>
  <div>
    <!-- 卡片视图区域 运营人员-->
    <el-card>
      <!-- 添加角色按钮区域  -->
      <el-row :gutter="20" v-if="tianjiajuese == true || shanchu == true">
        <el-col :span="2">
          <el-button type="success" icon="el-icon-plus" @click="showEditRole"
            v-if="tianjiajuese == true">添加角色</el-button>
        </el-col>
        <el-col :span="1">
          <el-button type="danger" :disabled="del_btn" @click="removeRoleById" icon="el-icon-delete"
            v-if="shanchu == true">批量删除</el-button>
        </el-col>
      </el-row>
      <!-- 角色列表区域 -->
      <el-table :data="rolesList" border @selection-change="handleSelectionChange" empty-text="暂无数据">
        <!-- 索引列 -->
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="角色ID" prop="id"></el-table-column>
        <el-table-column align="center" label="角色名称" prop="rolename"></el-table-column>
        <!-- <el-table-column align="center" label="角色类型" prop="roleTypeName"></el-table-column> -->
        <el-table-column align="center" label="角色描述" prop="des"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="updateTime" width="100"></el-table-column>
        <el-table-column align="center" label="操作" width="300"
          v-if="bianji == true && shanchu == true && fenpeiquanxian == true">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="showEditRole(scope.row)"
              v-if="bianji == true">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="removeRoleById(scope.row)"
              v-if="shanchu">删除</el-button>
            <el-button size="mini" type="warning" icon="el-icon-setting" @click="showSetPowerDialog(scope.row)"
              v-if="fenpeiquanxian">分配权限</el-button>
            <!-- @click="change_user(scope.row)" -->
            <!-- <el-button size="mini" icon="el-icon-setting" @click="xiugai(scope.row)">修改权限</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加角色&&修改角色的对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible" width="35%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
        <el-form-item label="角色名称" prop="rolename">
          <el-input placeholder="请输入角色名称" v-model="editForm.rolename"></el-input>
        </el-form-item>
        <!-- <el-form-item label="角色类型" prop="roleTypeName" v-if="title == '编辑角色'">
          <el-select v-model="editForm.roleTypeName" placeholder="请选择角色类型" style="width: 100%;">
            <el-option v-for="item in roleTypeList" :key="item.roleType" :label="item.roleTypeName"
              :value="item.roleTypeName">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="角色描述" prop="des">
          <el-input placeholder="请输入角色描述" v-model="editForm.des"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分配权限的对话框 -->
    <el-dialog title="分配权限" :visible.sync="dialoag" width="25%" @close="setPowerDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 :expand-on-click-node="defKeys.length==0?false:true"-->
      <el-tree :data="powerList" :props="treeProps" :default-expanded-keys="defKeys" show-checkbox node-key="id"
        @check-change="handleNodeClick" ref="treeRef" :check-strictly="true" style="width: 100%;height: 80%;">
      </el-tree>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="setPowerDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="allotPower">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'roleWorld',
  data() {
    return {
      tuzi: '',
      tianjiajuese: false,
      bianji: false,
      shanchu: false,
      fenpeiquanxian: false,
      newarr: [],
      quxiaoarr: [],
      arr: [],
      hr: '',
      fen: false,
      flag: false,
      del_btn: true,
      user_title: '',
      dialoag: false,
      queryInfo: {
        powerName: '',
        page: 1,
        limit: 100,
        // 当前即将被分配权限的角色id
        roleId: 0,
        id: 0,
      },
      title: '',
      editForm: {
        rolename: null,
        // roleType: null,
        roleTypeName: null,
        des: null,
        id: null
      },
      editFormRules: {
        rolename: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
        // ,
        // roleTypeName: [
        //   { required: true, message: '角色类型不能为空', trigger: 'blur' }
        // ]
      },
      shanchuarr: [],
      editDialogVisible: false,
      // 所有角色列表数据
      rolesList: [],
      // 角色类型数据
      // roleTypeList: [
      //   { roleType: 0, roleTypeName: '管理员' },
      //   { roleType: 1, roleTypeName: '商户' },
      //   { roleType: 2, roleTypeName: '运营商(编辑)' },
      //   { roleType: 3, roleTypeName: '运营商(只读)' },
      //   { roleType: 4, roleTypeName: '发卡' },
      //   { roleType: 5, roleTypeName: '保留' },
      //   { roleType: 6, roleTypeName: '保留' },
      //   { roleType: 7, roleTypeName: '保留' },
      //   { roleType: 9996, roleTypeName: '销售' },
      //   { roleType: 9997, roleTypeName: '平台财务' },
      //   { roleType: 9998, roleTypeName: '普通管理员' },
      //   { roleType: 9999, roleTypeName: '售后' }
      // ],
      // 控制分配权限对话框的显示与隐藏
      powerDialogVisible: false,
      // 所有权限数据
      powerList: [],
      // 角色对应权限数据
      roleLogList: [],
      // 树形控件的属性绑定对象
      treeProps: {
        label: 'menuname',
        children: 'childList'
      },
      // 默认选中节点Id值的数组
      defKeys: [],
      ids: null,
      sttt: null,
      aaa: [],
      ntr: [],
      cdn: [],
      abc: []
    }
  },
  watch: {
    defKeys: {
      handler(newval) {
        this.defKeys = newval;
      }
    }
  },
  created() {
    caozuo(142).then(res => {
      this.bianji = res;
    });
    caozuo(163).then(res => {
      this.tianjiajuese = res;
    });
    caozuo(141).then(res => {
      this.shanchu = res;
    });
    caozuo(125).then(res => {
      this.fenpeiquanxian = res;
    });
    this.getRolesList();
    if (this.ids != null) {
      this.del_btn = !this.del_btn
    } else {
      this.del_btn = true
    };
    this.default_value();
  },
  methods: {
    handleNodeClick() {
      const that = this;
      // data, check, subCheck
      let nodes = that.$refs.treeRef.getCheckedNodes(false, false);
      nodes.forEach(item => {
        that.tuzi += item.id + ','
      })
      that.tuzi = that.tuzi.substring(0, that.tuzi.length - 1);
      //选中所有下级节点
      let values = that.tuzi.split(',');
      // console.log(values, '206');
      let abc = '';
      let bac = '';
      let cba = '';
      let selectedValues = '';
      // 遍历原始数据
      // 遍历原始数组
      cba = that.powerList.filter(obj => values.includes(obj.id.toString()));
      // selectedValues += cba.id + ',';
      console.log(cba[0],'213');
      for (let i = 0; i < cba[0].childList.length; i++) {
        abc += cba[0].childList[i].id;
        for (let j = 0; j < cba[0].childList[i].length; j++) {
          bac += cba[0].childList[i][j].id;
          bac = bac.substring(0, bac.length - 1);
        }
      }
      that.defKeys = (selectedValues + abc + bac).split(',');
      that.defKeys = that.defKeys.map((item) => item * 1);
    },
    async default_value() {
      // 获取所有权限的数据
      const { data: res } = await this.$http.get('adminMenu/getAllAdminMenu', {
        params: {
          name: null,
          page: 1,
          limit: 100
        }
      })
      this.powerList = res.data;
      this.powerList.forEach(item => {
        item.childList.forEach(ite => {
          ite.childList = ite.childList3;
        })
      });
    },
    // 获取所有角色的列表
    async getRolesList() {
      const { data: res } = await this.$http.get('adminRole/getAllAdminRole', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error('获取角色列表失败！')
      }
      this.rolesList = res.data;
    },
    // 展示角色对话框事件
    showEditRole(row) {
      this.editDialogVisible = true;
      if (row.id == '' | row.id == null || row.id == undefined) {
        this.title = '添加角色'
      } else {
        this.title = '编辑角色'
        this.$http.get('adminRole/getAdminRoleById/' + row.id).then(res => {
          if (res.data.code !== 0) {
            return this.$message.error('查询角色失败！')
          } else {
            this.editForm = res.data.data;
          }
        })
      }
    },
    // 添加&&编辑角色事件
    editRole() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        this.editForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (this.title == '添加角色') {
          const { data: res } = await this.$http.post('adminRole/addAdminRole', this.editForm)
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('添加角色成功！')
          this.editDialogVisible = false
          this.getRolesList()
        } else {
          const { data: res } = await this.$http.post('adminRole/updateAdminRoleById', this.editForm)
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('编辑角色成功！')
          this.editDialogVisible = false
          this.getRolesList()
        }
      })
    },
    // 监听添加&&修改对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    // 复选框的改变事件
    handleSelectionChange(value) {
      this.ids = '';
      if (value.length > 0) {
        if (value.length > 1) {
          this.del_btn = false;
          value.forEach(item => {
            this.ids += item.id + ','
          })
          this.ids = this.ids.substring(0, this.ids.length - 1)
        } else {
          this.ids = value[0].id
        }
      } else {
        this.del_btn = true;
      }
    },
    // 根据id删除对应的角色

    // 删除
    async removeRoleById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('取消了删除角色！')
      }
      if (this.ids == '' && row.id !== null || row.id !== undefined) {
        this.ids = row.id;
      };
      await this.$http.post('adminRole/delAdminRoleById/' + this.ids + '/' + Number(window.sessionStorage.getItem('adminId'))).then(res => {
        if (res) this.$message.success('删除角色成功！')
        this.getRolesList()
      }).catch(err => {
        if (err)
          this.$message.success('删除角色失败！')
      })
    },
    // 展示分配权限的对话框
    async showSetPowerDialog(row) {
      this.queryInfo.roleId = row.id;
      this.default_value();
      await this.$http.get('adminRoleMenu/getAllAdminRoleMenu', {
        params: {
          roleId: this.queryInfo.roleId,
          page: 1,
          limit: 8
        }
      }).then(res => {
        this.dialoag = true;
        if (res.data.length < 1) {
          this.queryInfo.id = null;
        } else {
          if (res.data.data[0].id !== null) {
            this.queryInfo.id = res.data.data[0].id;
          } else {
            this.queryInfo.id = res.data.data[1].id;
          }
        }
      });
      const { data: resd } = await this.$http.get('adminRoleMenu/getAdminRoleMenuById/' + this.queryInfo.id)
      if (resd.data.menuId == null || resd.data.menuId == '' || resd.data.menuId == undefined) return this.dialoag = true;
      if (resd.data.menuId.length < 1) return
      if (resd.data.menuId.includes(',')) {
        this.defKeys = resd.data.menuId.split(',').map((item) => item * 1);
      } else {
        this.defKeys.push(resd.data.menuId * 1);
      }
      this.$refs.treeRef.setCheckedKeys(this.defKeys);

      this.dialoag = true;
    },
    // 监听分配权限的对话框的关闭事件
    setPowerDialogClosed() {
      this.$router.push('/blank')
      this.arr = [];
      this.cdn = [];
      this.shanchuarr = [];
      this.defKeys = [];
      this.$refs.treeRef.setCheckedKeys([]);
      this.dialoag = false
    },
    // 确定添加/编辑角色分配权限
    async allotPower() {
      let selectedNodes = this.$refs.treeRef.getCheckedNodes();
      selectedNodes.forEach(item => {
        this.hr += item.id + ',';
      });
      this.hr = this.hr.substring(0, this.hr.length - 1);
      const { data: res } = await this.$http.post('adminRoleMenu/updateAdminRoleMenuById', { roleId: this.queryInfo.roleId, menuId: this.hr, id: this.queryInfo.id })
      if (res.code == 0) {
        this.$message.success('分配权限成功！');
        this.arr = [];
        this.cdn = [];
        this.shanchuarr = [];
        this.defKeys = [];
        this.getRolesList()
      } else {
        this.$message.error(res.msg)
      }
      this.dialoag = false;
    }
  }
}
</script>