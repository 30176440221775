<template>
  <div>
    <el-dialog title="上传小程序文件" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-upload class="upload-demo" ref="upload" action="https://jsonplaceholder.typicode.com/posts/"
        :on-change="handlePreview" :on-remove="handleRemove" multiple :limit="6" :file-list="fileList"
        :auto-upload="false" accept=".p12, .pem">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" v-debounce="ok">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 卡片视图区域 APP应用-->
    <el-card>
      <!--  -->
      <el-row :gutter="20">
        <el-col :span="3">
          <!-- @change="current" -->
          <el-input placeholder="请输入小程序名称" clearable v-model="queryInfo.name" @clear="getWechartAppletList">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="chaxun">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <el-col :span="3" v-if="tianjiaweixinxiaochengxu">
          <el-button type="success" icon="el-icon-plus" @click="showEdit(0)">添加微信小程序</el-button>
        </el-col>
        <el-col :span="3" v-if="tianjiaweixinxiaochengxu">
          <el-button type="success" icon="el-icon-plus" @click="showEdit(1)">添加支付宝小程序</el-button>
        </el-col>
        <el-col :span="2" v-if="shanchu">
          <el-button type="danger" icon="el-icon-delete" @click="removeById">批量删除</el-button>
        </el-col>
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="wechartAppletList" border @selection-change="handleSelectionChange" @row-dblclick="dbclick"
        empty-text="暂无数据">
        <el-table-column align="center" width="55" type="selection"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="24" type="flex" class="row-bg" justify="space-around">
                <el-col :span="5">
                  <el-form-item label="ID：">
                    <span>{{ scope.row.id }}</span>
                  </el-form-item>
                  <el-form-item label="小程序名称：">
                    <span>{{ scope.row.appname }}</span>
                  </el-form-item>
                  <el-form-item label="小程序ID：">
                    <span>{{ scope.row.appId }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商户号：">
                    <span>{{ scope.row.merchantId }}</span>
                  </el-form-item>
                  <el-form-item label="公司名称：">
                    <span>{{ scope.row.companyName }}</span>
                  </el-form-item>
                  <!-- <el-form-item label="商户：">
                    <span>{{ scope.row.adminName }}</span>
                  </el-form-item> -->
                </el-col>
                <el-col :span="7" style="overflow: hidden;white-space: nowrap;width: 500px;">
                  <el-form-item label="证书路径：">
                    <span>{{ scope.row.certificatePath }}</span>
                  </el-form-item>
                  <el-form-item label="API密钥：">
                    <span>{{ scope.row.apiKey }}</span>
                  </el-form-item>
                  <el-form-item label="支付密码：">
                    <span>{{ scope.row.paymentPassword }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="扫码标识：">
                    <span>{{ scope.row.organizationCode }}</span>
                  </el-form-item>
                  <el-form-item label="小程序密钥：">
                    <span>{{ scope.row.appSecret }}</span>
                  </el-form-item>
                  <el-form-item label="创建时间：">
                    <span>{{ scope.row.createTime }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="ID" prop="id" width="80"></el-table-column> -->
        <el-table-column align="center" label="小程序名称" prop="appname"></el-table-column>
        <el-table-column align="center" label="小程序类型" prop="tag">
          <template slot-scope="scope">
            <el-tag :type="scope.row.tag == 1 ? 'success' : scope.row.tag == 2 ? 'warning' : 'info'"
              v-if="scope.row.tag">{{ scope.row.tag == 1 ? '微信' : scope.row.tag == 2 ? '支付宝' : '' }}</el-tag>
            <el-tag v-else>--</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="APPID(小程序ID)" prop="appId">
          <template slot-scope="scope">
            <span v-if="scope.row.appId">{{ scope.row.appId }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="商户" prop="adminName"></el-table-column> -->
        <el-table-column align="center" label="商户号" prop="merchantId">
          <template slot-scope="scope">
            <span v-if="scope.row.merchantId">{{ scope.row.merchantId }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="扫码标识" prop="organizationCode"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="260">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{ scope.row.createTime }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="360"
          v-if="bianji == true || shanchu == true || shangchuanxiaochengxuwenjian == true || quanxians == true">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="showEdit(scope.row)" icon="el-icon-edit"
              v-if="bianji == true">编辑</el-button>
            <el-button size="small" type="danger" icon="el-icon-delete" @click="removeById(scope.row)"
              v-if="shanchu == true">删除</el-button>
            <el-button size="small" type="warning" v-if="quanxians" @click="mini_app(scope.row)">上传小程序文件</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 支付宝小程序 -->
    <el-dialog @submit.native.prevent :title="titles" :visible.sync="editDialogVisible2" width="55%"
      @close="editDialogClosed2" append-to-body :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="wechartAppletForm2" :rules="wechartAppletRules2" ref="powerStationRef2"
        style="margin-left: -10%;" label-width="200px">
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="wechartAppletForm2.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="小程序名称" prop="appname">
          <el-input v-model="wechartAppletForm2.appname" placeholder="请输入小程序名称"></el-input>
        </el-form-item>
        <el-form-item label="小程序ID" prop="appId">
          <el-input v-model="wechartAppletForm2.appId" placeholder="请输入支付宝小程序ID"></el-input>
        </el-form-item>
        <el-form-item label="小程序密钥" prop="appSecret">
          <el-input v-model="wechartAppletForm2.appSecret" placeholder="请输入支付宝小程序密钥"></el-input>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="商户秘钥" prop="merchantPrivateKey">
              <el-input v-model="wechartAppletForm2.merchantPrivateKey" placeholder="请输入商户秘钥"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付宝商户公钥" prop="alipayPublicKey">
              <el-input v-model="wechartAppletForm2.alipayPublicKey" placeholder="请输入支付宝商户公钥"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="扫码标识" prop="organizationCode">
              <el-input v-model="wechartAppletForm2.organizationCode" placeholder="请输入组织机构代码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通知日期">
              <!-- value-format="yyyy-MM-dd HH:mm" -->
              <el-date-picker v-model="value2" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" format="yyyy-MM-dd HH:mm" @change="changeTimeall2">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客服电话">
              <el-input v-model="wechartAppletForm2.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通知" prop="notice">
              <el-input v-model="wechartAppletForm2.notice" placeholder="请输入本站通知"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="自营商户" prop="adminIds">
              <el-select v-model="wechartAppletForm2.adminIds" placeholder="请选择商户" filterable multiple
                @change="changee2">
                <el-option v-for="item in shanghuList2" :key="item.id" :label="item.realname" :value="item.id"
                  :disabled="item.disabled">
                  {{ item.realname }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第三方商户" prop="otherStationIds">
              <el-select v-model="wechartAppletForm2.otherStationIds" placeholder="请选择商户" filterable multiple
                @change="changeo2">
                <el-option v-for="item in operatorListing2" :key="item.id" :label="item.realname" :value="item.id"
                  :disabled="item.disabled">
                  <!-- || disabledItems.includes(item.id) -->
                  {{ item.realname }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed2">取 消</el-button>
        <el-button type="primary" v-debounce="editWechartApplet2">确 定</el-button>
      </span>
    </el-dialog>
    <!--微信小程序的添加&&编辑对话框 -->
    <el-dialog @submit.native.prevent :title="title" :visible.sync="editDialogVisible" width="55%"
      @close="editDialogClosed" append-to-body :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="wechartAppletForm" :rules="wechartAppletRules" ref="powerStationRef" style="margin-left: -10%;"
        label-width="200px">
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="wechartAppletForm.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="小程序名称" prop="appname">
          <el-input v-model="wechartAppletForm.appname" placeholder="请输入小程序名称"></el-input>
        </el-form-item>
        <el-form-item label="小程序ID" prop="appId">
          <el-input v-model="wechartAppletForm.appId" placeholder="请输入小程序ID"></el-input>
        </el-form-item>
        <el-form-item label="小程序密钥" prop="appSecret">
          <el-input v-model="wechartAppletForm.appSecret" placeholder="请输入小程序密钥"></el-input>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="商户号" prop="merchantId">
              <el-input v-model="wechartAppletForm.merchantId" placeholder="请输入商户号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付密码" prop="paymentPassword">
              <el-input v-model="wechartAppletForm.paymentPassword" placeholder="请输入支付密码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Api密钥" prop="apiKey">
              <el-input v-model="wechartAppletForm.apiKey" placeholder="请输入Api密钥"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="扫码标识" prop="organizationCode">
              <el-input v-model="wechartAppletForm.organizationCode" placeholder="请输入组织机构代码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订阅模板一" prop="template1">
              <el-input v-model="wechartAppletForm.template1" placeholder="请输入订阅模板一"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订阅模板二" prop="template2">
              <el-input v-model="wechartAppletForm.template2" placeholder="请输入订阅信息模板id(微信)二"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订阅模板三" prop="template3">
              <el-input v-model="wechartAppletForm.template3" placeholder="请输入订阅模板三"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通知日期">
              <!-- value-format="yyyy-MM-dd HH:mm" -->
              <el-date-picker v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" format="yyyy-MM-dd HH:mm" @change="changeTimeall">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客服电话">
              <el-input v-model="wechartAppletForm.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="通知" prop="notice">
              <el-input v-model="wechartAppletForm.notice" placeholder="请输入本站通知"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="自营商户" prop="adminIds">
              <el-select v-model="wechartAppletForm.adminIds" placeholder="请选择商户" filterable multiple @change="changee">
                <el-option v-for="item in shanghuList" :key="item.id" :label="item.realname" :value="item.id"
                  :disabled="item.disabled">
                  {{ item.realname }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第三方商户" prop="otherStationIds">
              <el-select v-model="wechartAppletForm.otherStationIds" placeholder="请选择商户" filterable multiple
                @change="changeo">
                <el-option v-for="item in operatorListing1" :key="item.id" :label="item.realname" :value="item.id"
                  :disabled="item.disabled">
                  <!-- || disabledItems.includes(item.id) -->
                  {{ item.realname }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editWechartApplet">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'wechartAppletWorld',
  data() {
    return {
      duplicatesOtherId: null,
      duplicatesAdminid: null,
      value2: ['', ''],
      ids_now: 0,
      tianjiaweixinxiaochengxu: false,
      shanchu: false,
      bianji: false,
      shangchuanxiaochengxuwenjian: false,
      merchantId: null,
      mini_id: null,
      value1: ['', ''],
      operateId: null,
      num: 0,
      flaging_mu: true,
      queryInfo: {
        name: '',
        companyInitial: '',
        adminId: window.sessionStorage.getItem('adminId'),
        page: 1,
        limit: 8
      },
      adminIds: null,
      otherStationIds: null,
      // 微信小程序列表
      wechartAppletList: [],
      wechartAppletForm: {
        adminIds: '',
        id: null,
        companyName: '',
        apiKey: '',
        appname: '',
        // appId: null,
        appSecret: '',
        merchantId: null,
        merchantPrivateKey: '',
        notifyUrl: '',
        returnUrl: '',
        charset: null,
        signType: null,
        gatewayUrl: '',
        alipayPublicKey: '',
        paymentPassword: '',
        template1: '',
        template2: '',
        template3: '',
        organizationCode: '',
        // selfStationIds: null,
        otherStationIds: null,
        notice: '',
        noticeEndTime: '',
        noticeStartTime: '',
        tag: 1,
      },
      // 支付宝
      wechartAppletForm2: {
        adminIds: '',
        id: null,
        appId: '',
        companyName: '',
        appname: '',
        appSecret: '',
        merchantPrivateKey: '',
        alipayPublicKey: '',
        organizationCode: '',
        otherStationIds: null,
        notice: '',
        noticeEndTime: '',
        noticeStartTime: '',
        tag: 2,
      },
      // 总数据条数
      total: 0,
      title: null,
      titles: null,
      dialogVisible: false,
      // 编辑微信小程序
      editDialogVisible: false,
      // 编辑支付宝小程序
      editDialogVisible2: false,
      // 微信规则
      wechartAppletRules: {
        appname: [
          { required: true, message: '微信小程序的小程序名称不能为空', trigger: 'blur' }
        ],
        appId: [
          { required: true, message: '微信小程序的小程序ID不能为空', trigger: 'blur' }
        ],
        merchantId: [
          { required: true, message: '微信小程序的商户号不能为空', trigger: 'blur' }
        ],
        adminIds: [
          { required: true, message: '微信小程序的商户账户不能为空', trigger: 'blur' }
        ],
        appSecret: [
          { required: true, message: '微信小程序的密钥不能为空', trigger: 'blur' }
        ],
        paymentPassword: [
          { required: true, message: '微信小程序的支付密码不能为空', trigger: 'blur' }
        ],
        organizationCode: [
          { required: true, message: '微信小程序的订阅信息模板不能为空', trigger: 'blur' }
        ],
        apiKey: [
          { required: true, message: '微信小程序的API密钥不能为空', trigger: 'blur' }
        ]
      },
      // 支付宝规则
      wechartAppletRules2: {
        // companyName: [
        //   { required: true, message: '支付宝小程序的公司名称不能为空', trigger: 'blur' }
        // ],
        appname: [
          { required: true, message: '支付宝小程序的名称不能为空', trigger: 'blur' }
        ],
        appId: [
          { required: true, message: '支付宝小程序的ID不能为空', trigger: 'blur' }
        ],
        appSecret: [
          { required: true, message: '支付宝小程序的密钥不能为空', trigger: 'blur' }
        ],
        alipayPublicKey: [
          { required: true, message: '支付宝小程序的商户公钥不能为空', trigger: 'blur' }
        ],
        merchantPrivateKey: [
          { required: true, message: '支付宝小程序的商户私钥不能为空', trigger: 'blur' }
        ],
        adminIds: [
          { required: true, message: '支付宝小程序的自营商户账户不能为空', trigger: 'blur' }
        ],
        organizationCode: [
          { required: true, message: '支付宝小程序的扫码标识不能为空', trigger: 'blur' }
        ]
      },
      // 商户列表
      operatorListing1: [],
      shanghuList: [],
      operatorListing2: [],
      shanghuList2: [],
      otherList: [],
      otherList2: [],
      // 场站列表
      powerStationList: [],
      powerStationIds: '',
      ids: '',
      change_limit: null,
      fileList: [],
      admin: '',
      caozuoquanxian: false,
      Todaydate: '',
    }
  },
  computed: {
    current() {
      this.queryInfo.page = 1;
    },
    quanxians() {
      if (window.sessionStorage.getItem('roleId') == 1 && this.shangchuanxiaochengxuwenjian == true) {
        return true
      } else {
        return false
      }
    },
    disabledItems() {
      return this.wechartAppletForm.adminIds; // 返回左侧多选框选择的项，用于禁用右侧多选框中对应的项目
    },
  },
  watch: {
    wechartAppletList: {
      handler(newval) {
        this.wechartAppletList = newval;
      }
    },
    'wechartAppletForm.noticeStartTime'(newvalue) {
      if (newvalue == null) return this.wechartAppletForm.noticeStartTime = ''
    },
    'wechartAppletForm.noticeEndTime'(newvalue) {
      if (newvalue == null) return this.wechartAppletForm.noticeEndTime = ''
    },
    'wechartAppletForm2.noticeStartTime'(newvalue) {
      if (newvalue == null) return this.wechartAppletForm2.noticeStartTime = ''
    },
    'wechartAppletForm2.noticeEndTime'(newvalue) {
      if (newvalue == null) return this.wechartAppletForm2.noticeEndTime = ''
    }
  },
  created() {
    caozuo(138).then(res => {
      this.tianjiaweixinxiaochengxu = res;
    });
    caozuo(139).then(rese => {
      this.bianji = rese;
    });
    caozuo(140).then(resb => {
      this.shanchu = resb;
    });
    caozuo(128).then(resz => {
      this.shangchuanxiaochengxuwenjian = resz;
    });
    this.getWechartAppletList();
    this.getDateYear();
      this.getOperatorList();
    
  },
  methods: {
    getDateYear() {
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let dates = date.getDate()
      return this.Todaydate = year + '-' + month + '-' + dates
    },
    // 取消添加、编辑微信小程序
    editDialogClosed() {
      this.$refs.powerStationRef.resetFields();
      // this.wechartAppletForm={};
      this.editDialogVisible = false;
      this.wechartAppletList.forEach(item => {
        item.b_j_anniu = false;
      });
    },
    // 取消添加、编辑支付宝小程序
    editDialogClosed2() {
      this.$refs.powerStationRef2.resetFields();
      // this.wechartAppletForm={};
      this.editDialogVisible2 = false;
      this.wechartAppletList.forEach(item => {
        item.b_j_anniu = false;
      });
    },
    // 查询
    chaxun() {
      this.getWechartAppletList()
    },
    // 根据分页获取对应的微信小程序列表
    async getWechartAppletList() {
      const { data: res } = await this.$http.get('applet/getAllAppletApp', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      } else {
        this.wechartAppletList = res.data;
        this.wechartAppletList.forEach(item => {
          item.b_j_anniu = false; item.s_c_x_c_x_anniu = false;
        });
        this.total = res.count;
      }
    },
    // 确认上传文件
    ok() {
      const formData = new FormData();
      this.fileList.forEach(item => {
        formData.append('files', item.raw);
        formData.append('id', this.mini_id);
        formData.append('merchantId', this.merchantId);
      });
      // 发送文件上传请求到后台接口
      this.$http.post('/applet/upLoadCert', formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data
        }
      })
        .then(response => {
          // 处理上传成功的响应结果
          if (response.data.code == 0) {
            this.$message.success(response.data.msg);
            this.dialogVisible = false;
            this.getWechartAppletList();
          } else {
            this.$message.error(response.data.msg);
          }
        })
        .catch(error => {
          // 处理上传失败的错误信息
          console.error(error);
        })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file, fileList) {
      this.fileList = fileList;
    },
    // 上传文件
    handleExceed(files, fileList) {
      if (fileList.length < 3) {
        this.$message.warning('最少上传3个文件')
      } else {
        this.$message.warning(`当前限制选择 6 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 微信小程序自选商户
    changee() {
      let abc = new Set(this.wechartAppletForm.adminIds); 
      this.operatorListing1.forEach(item => {
        if (abc.has(item.id)) return item.disabled = true; 
        // return item.disabled = false; 
      });   
      let bac=new Set(this.wechartAppletForm.otherStationIds);
      this.shanghuList.forEach(item => {
        if (bac.has(item.id)) return item.disabled = true;  
        //  return item.disabled = false; 
      });
    },
    // 支付宝小程序自选商户
    changee2() {
      let abc = new Set(this.wechartAppletForm2.adminIds); 
      this.operatorListing2.forEach(item => {
        if (abc.has(item.id)) return item.disabled = true; 
        // return item.disabled = false; 
      }); 
       let bac=new Set(this.wechartAppletForm2.otherStationIds);
      this.shanghuList2.forEach(item => {
        if (bac.has(item.id)) return item.disabled = true;   
        // return item.disabled = false; 
      });
    },
    // 支付宝小程序（更新时间）
    changeTimeall2(value) {
      const that = this;
      if (value) {
        that.wechartAppletForm2.noticeStartTime = value[0];
        that.wechartAppletForm2.noticeEndTime = value[1];
        if (that.wechartAppletForm2.noticeStartTime == that.wechartAppletForm2.noticeEndTime) {
          that.$message.error('起始时间不得为同一天！');
          that.wechartAppletForm2.noticeStartTime = ''
          that.wechartAppletForm2.noticeEndTime = ''
        }
      } else {
        that.wechartAppletForm2.noticeStartTime = '';
        that.wechartAppletForm2.noticeEndTime = '';
      }
    },
    // 微信小程序更新时间
    changeTimeall(value) {
      const that = this;
      if (value) {
        that.wechartAppletForm.noticeStartTime = value[0];
        that.wechartAppletForm.noticeEndTime = value[1];
        if (that.wechartAppletForm.noticeStartTime == that.wechartAppletForm.noticeEndTime) {
          that.$message.error('起始时间不得为同一天！');
          that.wechartAppletForm.noticeStartTime = ''
          that.wechartAppletForm.noticeEndTime = ''
        }
      } else {
        that.wechartAppletForm.noticeStartTime = '';
        that.wechartAppletForm.noticeEndTime = '';
      }
    },
    // 微信小程序第三方商户
    changeo() {
      let abc = new Set(this.wechartAppletForm.otherStationIds);
      let bac=new Set(this.wechartAppletForm.adminIds);
      this.operatorListing1.forEach(item => {
        if (bac.has(item.id)) return item.disabled = true; 
        return item.disabled = false; 
      });
      this.shanghuList.forEach(item => {
        if (abc.has(item.id)) return item.disabled = true; 
      });
    },
    // 支付宝小程序第三方商户
    changeo2() {
      let abc = new Set(this.wechartAppletForm2.otherStationIds);
      let bac=new Set(this.wechartAppletForm2.adminIds);
      this.operatorListing2.forEach(item => {
        if (bac.has(item.id)) return item.disabled = true; 
        return item.disabled = false; 
      });
      this.shanghuList2.forEach(item => {
        if (abc.has(item.id)) return item.disabled = true; 
      });
    },
    mini_app(row) {
      this.dialogVisible = true;
      this.mini_id = row.id;
      row.s_c_x_c_x_anniu = true;
      this.merchantId = row.merchantId;
    },
    // 上传文件
    uploadFiles(event) {
      // 创建一个FormData对象
      const formData = new FormData();
      // 获取选择的文件列表
      const files = event.target.files;
      // 遍历文件列表，并将每个文件添加到FormData对象中
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
        formData.append('id', this.mini_id);
        formData.append('merchantId', this.merchantId);
      }
      // 发送文件上传请求到后台接口
      this.$http.post('/applet/upLoadCert', formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data
        }
      })
        .then(response => {
          // 处理上传成功的响应结果
          if (response.data.code == 0) {
            this.$message.success(response.data.msg);
            this.dialogVisible = false;
          } else {
            this.$message.error(response.data.msg);
          }
        })
        .catch(error => {
          // 处理上传失败的错误信息
          console.error(error);
        });
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
      this.wechartAppletForm.files = this.fileList;
      // 将选择的文件添加到表单数据中
    },
    // 重置
    chongzhi() {
      this.queryInfo = {
        name: '',
        companyInitial: '',
        adminId: Number(window.sessionStorage.getItem('adminId')),
        page: 1,
        limit: 8
      };
      this.getWechartAppletList()
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getWechartAppletList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getWechartAppletList()
    },
    // 获取运营账户列表数据
    async getOperatorList() {
      const { data: res } = await this.$http.get('admin/getAllAdminName')
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.$http.get('admin/getAllAdminName').then(resb => {
        let ntr = resb.data.data;
        for (let index = 0; index < ntr.length; index++) {
          const element = ntr[index];
          element.disabled = false
        };
        this.shanghuList = ntr;
        this.operatorListing1 = ntr;
        this.shanghuList2 = ntr;
        this.operatorListing2 = ntr;
      });
    },
    handleClose() {
      this.wechartAppletList.forEach(item => {
        item.s_c_x_c_x_anniu = false;
      });
      this.dialogVisible = false;
    },
    // 场站列表改变事件
    changePowerStation(e) {
      let str = e.slice(0, e.length);
      var ntr = '';
      this.powerStationIds = '';
      for (let index = 0; index < str.length; index++) {
        ntr += str[index] + ','
      };
      this.powerStationIds = ntr.substring(0, ntr.length - 1);
      console.log(this.powerStationIds, '346')
    },
    // 展示添加&&编辑微信小程序、支付宝小程序对话框
    async showEdit(row) {
      const that = this;
      if (row == 0) {
        that.wechartAppletForm = {};
        that.title = '添加微信小程序';
        that.editDialogVisible = true;
      } else if (row == 1) {
        that.wechartAppletForm2 = {};
        that.titles = '添加支付宝小程序';
        that.editDialogVisible2 = true;
      } else {
        let a1 = [];
        let b1 = [];
        that.ids_now = row.id;
        const { data: res } = await that.$http.get('applet/getAppletAppById/' + row.id);
        row.b_j_anniu = true;
        a1 = res.data.otherStationIds;
        b1 = res.data.adminIds;
        res.data.otherStationIds = a1.split(',').map(Number);
        res.data.adminIds = b1.split(',').map(Number);
        if (res.data.notice == null || res.data.notice == '' || res.data.notice == undefined) {
          res.data.noticeEndTime = '';
          res.data.noticeStartTime = '';
        } else {
          res.data.noticeEndTime = res.data.noticeEndTime == undefined && res.data.noticeEndTime == null ? '' : res.data.noticeEndTime;
          res.data.noticeStartTime = res.data.noticeStartTime == undefined && res.data.noticeStartTime == null ? '' : res.data.noticeStartTime;
          res.data.notice = res.data.notice == undefined && res.data.notice == null ? '' : res.data.notice;
        }
        that.editDialogVisible = row.tag == 1 ? true : false;
        that.title = row.tag == 1 ? '编辑微信小程序' : '';
        that.wechartAppletForm = row.tag == 1 ? res.data : {};
        that.wechartAppletForm.otherStationIds = row.tag == 1 ? res.data.otherStationIds : [];
        that.wechartAppletForm.adminIds = row.tag == 1 ? res.data.adminIds : [];
        that.value1 = row.tag == 1 ? [that.wechartAppletForm.noticeStartTime, that.wechartAppletForm.noticeEndTime] : ['', ''];;
        that.editDialogVisible2 = row.tag == 2 ? true : false;
        that.titles = row.tag == 2 ? '编辑支付宝小程序' : '';
        that.wechartAppletForm2 = row.tag == 2 ? res.data : {};
        that.wechartAppletForm2.otherStationIds = row.tag == 2 ? res.data.otherStationIds : '';
        that.wechartAppletForm2.adminIds = row.tag == 2 ? res.data.adminIds : '';
        that.value2 = row.tag == 2 ? [that.wechartAppletForm2.noticeStartTime, that.wechartAppletForm2.noticeEndTime] : ['', ''];
      if(row.tag == 1){
        let abc = new Set(that.wechartAppletForm.adminIds); 
      that.operatorListing1.forEach(item => {
        if (abc.has(item.id)) return item.disabled = true; 
        return item.disabled = false; 
      });   
      let bac=new Set(that.wechartAppletForm.otherStationIds);
      that.shanghuList.forEach(item => {
        if (bac.has(item.id)) return item.disabled = true;   
        return item.disabled = false; 
      });
      }
      if(row.tag==2){
        let abc1 = new Set(that.wechartAppletForm2.adminIds); 
      that.operatorListing2.forEach(item => {
        if (abc1.has(item.id)) return item.disabled = true; 
         return item.disabled = false; 
      });   
      let bac1=new Set(that.wechartAppletForm2.otherStationIds);
      that.shanghuList2.forEach(item => {
        if (bac1.has(item.id)) return item.disabled = true;   
        return item.disabled = false; 
      });
      }
      }
    },
    // 添加&&编辑事件（微信）
    async editWechartApplet() {
      const that = this;
      that.$refs.powerStationRef.validate(async valid => {
        if (!valid) return
        that.wechartAppletForm.adminId = window.sessionStorage.getItem('adminId');
        that.wechartAppletForm.notice = that.wechartAppletForm.notice == null || that.wechartAppletForm.notice == undefined ? '' : that.wechartAppletForm.notice;
        that.wechartAppletForm.noticeStartTime = that.wechartAppletForm.noticeStartTime == null || that.wechartAppletForm.noticeStartTime == undefined ? '' : that.wechartAppletForm.noticeStartTime;
        that.wechartAppletForm.noticeEndTime = that.wechartAppletForm.noticeEndTime == null || that.wechartAppletForm.noticeEndTime == undefined ? '' : that.wechartAppletForm.noticeEndTime;
        if (that.wechartAppletForm.notice !== '' && that.wechartAppletForm.noticeStartTime == '') return that.$message.error('通知内容已输入，请选择你的通知时间！！！');
        if (that.wechartAppletForm.notice == '' && that.wechartAppletForm.noticeStartTime !== '') return that.$message.error('您已选择通知时间，请输入您的通知内容！！！');
        if (that.title == '编辑微信小程序') {
          const a = that.wechartAppletForm.otherStationIds;
          const b = that.wechartAppletForm.adminIds;
          that.wechartAppletForm.otherStationIds = a.join(',');
          that.wechartAppletForm.adminIds = b.join(',');
          await that.$http.post('applet/updAppletApp', that.wechartAppletForm).then(res => {
            that.$message.success(res.data.msg);
            that.editDialogVisible = false;
            that.getWechartAppletList();
          }).catch(res => {
            that.$message.error(res.data.msg)
          })
        } else {
          // 添加微信小程序
          const a = that.wechartAppletForm.otherStationIds;
          const b = that.wechartAppletForm.adminIds;
          that.wechartAppletForm.tag='1';
          that.wechartAppletForm.otherStationIds = a.join(',');
          that.wechartAppletForm.adminIds = b.join(',');
          that.$http.post('applet/addAppletApp', that.wechartAppletForm).then(res => {
            if (res.data.code != 0) {
              that.$message.error(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
            }
            that.editDialogVisible = false;
            that.getWechartAppletList();
          }).catch(res => {
            that.$message.error(res.data.msg)
          })
        }
      })
    },
    // 添加&&编辑事件（支付宝）
    async editWechartApplet2() {
      const that = this;
      that.$refs.powerStationRef2.validate(async valid => {
        if (!valid) return
        that.wechartAppletForm2.adminId = window.sessionStorage.getItem('adminId');
        that.wechartAppletForm2.notice = that.wechartAppletForm2.notice == null || that.wechartAppletForm2.notice == undefined ? '' : that.wechartAppletForm2.notice;
        that.wechartAppletForm2.noticeStartTime = that.wechartAppletForm2.noticeStartTime == null || that.wechartAppletForm2.noticeStartTime == undefined ? '' : that.wechartAppletForm2.noticeStartTime;
        that.wechartAppletForm2.noticeEndTime = that.wechartAppletForm2.noticeEndTime == null || that.wechartAppletForm2.noticeEndTime == undefined ? '' : that.wechartAppletForm2.noticeEndTime;
        if (that.wechartAppletForm2.notice !== '' && that.wechartAppletForm2.noticeStartTime == '') return that.$message.error('通知内容已输入，请选择你的通知时间！！！');
        if (that.wechartAppletForm2.notice == '' && that.wechartAppletForm2.noticeStartTime !== '') return that.$message.error('您已选择通知时间，请输入您的通知内容！！！');
        if (that.titles == '编辑支付宝小程序') {
          let a1 = that.wechartAppletForm2.otherStationIds;
          let b1 = that.wechartAppletForm2.adminIds;
          that.wechartAppletForm2.otherStationIds = a1.join(',');
          that.wechartAppletForm2.adminIds = b1.join(',');
          await that.$http.post('applet/updAppletApp', that.wechartAppletForm2).then(res => {
            that.$message.success(res.data.msg);
            that.editDialogVisible2 = false;
            that.getWechartAppletList();
          }).catch(res => {
            that.$message.error(res.data.msg)
          })
        } else {
          // 添加支付宝小程序
          let a1 = that.wechartAppletForm2.otherStationIds;
          let b1 = that.wechartAppletForm2.adminIds;
          that.wechartAppletForm2.tag='2';
          that.wechartAppletForm2.otherStationIds = a1.join(',');
          that.wechartAppletForm2.adminIds = b1.join(',');
          that.$http.post('applet/addAppletApp', that.wechartAppletForm2).then(res => {
            if (res.data.code != 0) {
              that.$message.error(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
            }
            that.editDialogVisible2 = false;
            that.getWechartAppletList();
          }).catch(res => {
            that.$message.error(res.data.msg)
          })
        }
      })
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(e) {
      if (e.length > 0) {
        this.ids = ''
        e.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 删除微信小程序方法
    async removeById(row) {
      let abc = '';
      if (row.tag == 1) {
        abc = '微信'
      } else {
        abc = '支付宝'
      };
      const confirmResult = await this.$confirm(`此操作将永久删除该${abc}小程序, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info(`已经取消删除${abc}小程序！`)
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('applet/delAppletAppById/' + this.ids + '/' + window.sessionStorage.getItem('adminId'))
        if (res.code !== 0) {
          return this.$message.error(`删除${abc}小程序失败！`)
        }
        this.$message.success(`删除${abc}小程序成功！`)
        this.getWechartAppletList()
      } else {
        const { data: res } = await this.$http.post('applet/delAppletAppById/' + row.id + '/' + window.sessionStorage.getItem('adminId'))
        if (res.code !== 0) {
          return this.$message.error(`删除${abc}小程序失败！`)
        }
        this.$message.success(`删除${abc}小程序成功！`)
        this.getWechartAppletList()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>