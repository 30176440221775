import { render, staticRenderFns } from "./Settlement.vue?vue&type=template&id=53e9ae06&scoped=true&"
import script from "./Settlement.vue?vue&type=script&lang=js&"
export * from "./Settlement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e9ae06",
  null
  
)

export default component.exports