export function saveBlobToFile (res, filename) {
    var blob = new Blob([res], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename)
    } else {
      const oA = document.createElement('a')
      try {
        oA.href = URL.createObjectURL(blob)
      } catch (err) {
        const binaryData = []
        binaryData.push(blob)
        oA.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/vnd.ms-excel' }))
      }
      oA.download = filename
      oA.click()   
    }
  }