<template>
  <div>
    <!-- 卡片视图区域   电卡用户-->
    <el-card>
      <!--  -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :span="3" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:2;">卡号:</span><el-input style="flex:8;" placeholder="请输入车牌编号" clearable
            v-model="queryInfo.physicalCode" @clear="getOnAccountList" @change="current"></el-input>
        </el-col>
        <el-col :span="4" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">车牌编号:</span><el-input style="flex:7;" placeholder="请输入车牌编号" clearable
            v-model="queryInfo.cardNumber" @clear="getOnAccountList" @change="current"></el-input>
        </el-col>
        <el-col :span="4" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">持卡人:</span><el-input style="flex:7;" placeholder="请输入持卡人姓名" clearable
            v-model="queryInfo.cardName" @clear="getOnAccountList" @change="current">
          </el-input>
        </el-col>
        <el-col :span="4" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">电卡类型:</span> <el-select filterable style="flex:7;" v-model="queryInfo.electricCardType"
            placeholder="请选择电卡类型" clearable @clear="getOnAccountList" @change="current">
            <el-option v-for="item in electricCardType" :key="item.electricCardType" :label="item.label"
              :value="item.electricCardType"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">手机号:</span><el-input style="flex:7;" placeholder="请输入手机号" clearable
            v-model="queryInfo.cardTel" @clear="getOnAccountList" @change="current">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getOnAccountList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <!-- <el-col :span="4" style="margin-left: 40px;">
          <el-button @click="chongzhi">重置</el-button>
        </el-col> -->
      </el-row>
      <!-- table表格区域 -->

      <el-table :data="onAccountList" border stripe @selection-change="handleSelectionChange" @row-dblclick="dbclick"
        empty-text="暂无数据">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="8">
                  <el-form-item label="卡类型：">
                    <el-tag size="small" v-if="scope.row.electricCardType == 0">配置卡</el-tag>
                    <el-tag size="small" type="info" v-if="scope.row.electricCardType == 1">独立卡</el-tag>
                    <el-tag size="small" type="success" v-if="scope.row.electricCardType == 2">平台卡</el-tag>
                    <el-tag size="small" type="warning" v-if="scope.row.electricCardType == 3">超级卡</el-tag>
                  </el-form-item>
                  <el-form-item label="操作员：">
                    <span v-if="scope.row.cardKey">{{ scope.row.cardKey }}</span>
                  </el-form-item>
                  <el-form-item label="用户姓名：">
                    <span v-if="scope.row.cardName">{{ scope.row.cardName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡余额（元）：">
                    <el-tag v-if="scope.row.money">￥{{ scope.row.money }}</el-tag>
                  </el-form-item>
                  <el-form-item label="更新时间"><span>{{ scope.row.updateTime }}</span>
                  </el-form-item>
                  <el-form-item label="手机号：">
                    <span v-if="scope.row.cardTel">{{ scope.row.cardTel }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡号：">
                    <span v-if="scope.row.physicalCode">{{ scope.row.physicalCode }}</span>
                  </el-form-item>
                  <!-- <el-form-item label="禁用：" v-if="bianji">
                    <el-switch v-model="scope.row.electricCardState" active-color="#ff4949" inactive-color="#13ce66"
                      :disabled="scope.row.electricCardState === 1 ? true : false"></el-switch>
                  </el-form-item> -->
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡号" prop="physicalCode" width="140"></el-table-column>
        <el-table-column align="center" label="用户姓名" prop="cardName" width="140"></el-table-column>
        <el-table-column align="center" label="手机号" prop="cardTel" width="120"></el-table-column>
        <el-table-column align="center" label="车牌号" prop="carNumber" width="130">
          <template slot-scope="scope">
            <span> {{ scope.row.carNumber !== null && scope.row.carNumber !== '' ? scope.row.carNumber : '--' }} </span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="所属电站" prop="stationName">
          <template slot-scope="scope">
            <span> {{ scope.row.stationName !== null && scope.row.stationName !== '' ? scope.row.stationName : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡类型" prop="electricCardType" width="80">
          <template slot-scope="scope">
            <el-tag size="small" v-if="scope.row.electricCardType == 0">配置卡</el-tag>
            <el-tag size="small" type="info" v-if="scope.row.electricCardType == 1">独立卡</el-tag>
            <el-tag size="small" type="success" v-if="scope.row.electricCardType == 2">平台卡</el-tag>
            <el-tag size="small" type="warning" v-if="scope.row.electricCardType == 3">超级卡</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡余额（元）" prop="money" width="100">
          <template slot-scope="scope">
            <el-tag size="small" type="primary"> ￥{{ scope.row.money }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="禁用" prop="electricCardState" v-if="bianji" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.electricCardState"
              :active-color="scope.row.electricCardState == true ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.electricCardState == true ? '#ff4949' : '#13ce66'"
              @change="change_type(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="100">
          <template slot-scope="scope"><span v-if="scope.row.createTime != null">{{ scope.row.createTime }}</span><span
              v-else>--</span></template>
        </el-table-column>
        <el-table-column align="center" label="操作时间" prop="updateTime" width="100">
          <template slot-scope="scope"><span v-if="scope.row.updateTime != null">{{ scope.row.updateTime }}</span><span
              v-else>--</span></template>
        </el-table-column>
        <el-table-column align="left" label="操作" :width="bianji == true && chongzhituikuan == true ? '320px' : '100px'">
          <template slot-scope="scope">
            <el-button size="small" type="warning" @click="kajilu(scope.row)"
              :disabled="scope.row.k_j_l_anniu">卡记录</el-button>
            <el-button size="small" type="primary" @click="showOnAccountDialog(scope.row)" icon="el-icon-edit"
              v-if="bianji" :disabled="scope.row.b_j_anniu">编辑</el-button>
            <el-button size="small"
              v-if="scope.row.electricCardType == 2 && chongzhituikuan == true && scope.row.electricCardState !== 1"
              type="success" @click="cz_tk(scope.row)" :disabled="scope.row.c_z_t_k_anniu">充值/退款</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 编辑在线卡用户的对话框 -->
    <el-dialog title="编辑在线卡用户" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="onAccountForm" :rules="onAccountFormRules" ref="onAccountFormRef" label-width="80px">
        <el-form-item label="用户名" prop="cardName">
          <el-input v-model="onAccountForm.cardName"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="cardTel">
          <el-input v-model="onAccountForm.cardTel"></el-input>
        </el-form-item>
        <el-form-item label="车牌号" prop="carNumber">
          <el-input v-model="onAccountForm.carNumber"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editOnlineAccount">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 充值/退款 -->
    <el-dialog title="充值/退款" :visible.sync="cz_tk_dialoag" width="30%" @close="cz_tk_dialoagClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域  -->
      <div>
        <el-radio-group v-model="radio" style="width: 100%;margin-bottom: 40px;" @input="change_input">
          <el-radio :label="item.radio" v-for="item in radio_value_cz_tk" :key="item.radio" :value="item.radio">{{
        item.label }}</el-radio>
        </el-radio-group>
        <!-- 充值/退款 -->
        <el-form :model="onAccountForm" ref="editPresentFormRef" label-width="124px">
          <el-form-item label="总余额（元）" prop="money">
            <el-tag>￥{{ onAccountForm.money / 100 }}</el-tag>
          </el-form-item>
          <el-form-item label="已赠金额（元）" prop="giveMoney">
            <el-tag>￥{{ onAccountForm.giveMoney / 100 }}</el-tag>
          </el-form-item>
          <el-form-item :label="btn_title">
            <el-input-number v-model="num" controls-position="right" :precision="2" :step="0.1"
              :min="0"></el-input-number><span style="margin-left: 20px;"> 元</span>
          </el-form-item>
        </el-form>
      </div>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="cz_tk_dialoagClosed">取 消</el-button>
        <el-button type="primary" v-debounce="cz_tk_dialoag_ok">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 卡记录 -->
    <el-dialog title="卡记录" :visible.sync="kajilu_dialoag" width="80%" @close="kajilu_dialoagClosed" append-to-body>
      <!-- 内容主体区域 -->
      <div>
        <el-radio-group v-model="ids_all" style="width: 100%;" @input="change_input_card">
          <el-radio :label="item.ids_all" v-for="item in radio_value_JL" :key="item.ids_all" :value="item.ids_all">{{
        item.label }}</el-radio>
        </el-radio-group>
        <div v-if="ids_all == 0">
          <!-- 消费记录 -->
          <el-table :data="expendList" border>
            <el-table-column align="center" label="ID" prop="payId" width="100px"></el-table-column>
            <el-table-column align="center" label="卡号" prop="userNumber"></el-table-column>
            <el-table-column align="center" label="更新前金额（元）" prop=" startMoney">
              <template slot-scope="scope">
                <el-tag type="success">￥{{ scope.row.startMoney / 100 }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="变动金额（元）" prop="changMoney">
              <template slot-scope="scope">
                <el-tag type="success">￥{{ scope.row.changMoney / 100 }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作类型" prop="des" width="100px">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.usingType == '0' ? 'warning' : scope.row.usingType == '1' ? 'danger' : scope.row.usingType == '2' ? 'success' : scope.row.usingType == '3' ? 'default' : 'warning'">
                  {{ scope.row.usingType == '0' ? '消费' : scope.row.usingType == '1' ? '充值' : scope.row.usingType ==
        '2' ? '退款' : scope.row.usingType == '3' ? '赠送' : '欠费' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="新余额（元）" prop="newMoney">
              <template slot-scope="scope">
                <el-tag type="success">￥{{ scope.row.newMoney / 100 }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="流水单号" prop="billCode" width="200px"></el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            <div v-if="expendList.length < 1">
              <el-empty :image-size="200"></el-empty>
            </div>
          </el-table>
          <!-- 分页导航区域 -->
          <el-pagination @size-change="expendSizeChange" @current-change="expendCurrentChange"
            :current-page="expendInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="expendInfo.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="expendTotal" background>
          </el-pagination>
        </div>
        <!-- 操作记录 -->
        <div v-if="ids_all == 1">
          <el-table :data="caozuojiluList" border>
            <el-table-column align="center" label="ID" prop="id" width="100px"></el-table-column>
            <el-table-column align="center" label="用户姓名" prop="cardName"></el-table-column>
            <el-table-column align="center" label="手机号" prop="cardTel"></el-table-column>
            <el-table-column align="center" label="卡号" prop="physicalCode"></el-table-column>
            <el-table-column align="center" label="操作时间" prop="updateTime"></el-table-column>
            <div v-if="caozuojiluList.length < 1">
              <el-empty :image-size="200"></el-empty>
            </div>
          </el-table>
          <!-- 分页导航区域 -->
          <el-pagination @size-change="caozuojiluSizeChange" @current-change="caozuojiluCurrentChange"
            :current-page="caozuojiluInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="caozuojiluInfo.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="caozuojiluTotal" background>
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'onlineAccountWorld',
  data() {
    return {
      ids_all: 0,
      bianji: false,
      chongzhituikuan: false,
      radio_value_JL: [
        {
          ids_all: 0,
          label: '消费记录'
        },
        {
          ids_all: 1,
          label: '操作记录'
        }
      ],
      radio: 0,
      title_btn: null,
      cztk_title: '充值',
      radio_value_cz_tk: [
        {
          label: '充值',
          radio: 0,
        },
        {
          label: '退款',
          radio: 1
        }
      ],
      electricCardType: [
        {
          label: '配置卡',
          electricCardType: 0
        },
        {
          label: '独立卡',
          electricCardType: 1
        },
        {
          label: '平台卡',
          electricCardType: 2
        },
        {
          label: '超级卡',
          electricCardType: 3
        }
      ],
      caozuoquanxian: false,
      caozuojiluVisible: false,
      // 查询在线卡用户参数
      queryInfo: {
        cardNumber: null,
        phone: null,
        startTime: null,
        endTime: null,
        physicalCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId'),
        page: 1,
        limit: 10,
        cardName: null
      },
      expendInfo: {
        page: 1,
        limit: 10,
        electricCardInfoId: null,
      },
      caozuojiluInfo: {
        page: 1,
        limit: 8,
        physicalCode: null,
      },
      ids: null,
      editDialogVisible: false,
      // 在线卡用户数据
      onAccountList: [],
      caozuojiluList: [],
      onAccountForm: {},
      onAccountFormRules: {},
      total: 0,
      expendTotal: 0,
      caozuojiluTotal: 0,
      value: '',
      expendDialogVisible: false,
      expendList: [],
      editPresentDialogVisible: false,
      editRefundDialogVisible: false,
      num: 0,
      select_ids: 0,
      cz_tk_dialoag: false,
      kajilu_dialoag: false,
      btn_title: '充值'
    }
  },
  created() {
    caozuo(99).then(res => {
      this.bianji = res;
    });
    caozuo(152).then(res => {
      this.chongzhituikuan = res;
    });
    this.getOnAccountList()
  },
  methods: {
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        cardNumber: null,
        phone: null,
        startTime: null,
        endTime: null,
        physicalCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId'),
        page: 1,
        limit: 8,
        cardName: null
      };
      this.getOnAccountList()
    },
    async change_input_card(e) {
      this.expendList = [];
      this.caozuojiluList = [];
      if (e == 0) {
        const { data: res } = await this.$http.get('electric-card-info/getRecordsList', {
          params: this.expendInfo
        });
        this.$message({
          message: res.code == 0 ? '获取在线卡用户消费记录成功！' : '在线卡用户消费记录为空',
          type: res.code !== 0 ? 'error' : 'success'
        });
        if (res.code !== 0) return;
        this.expendList = res.data;
        this.expendTotal = res.count;
      } else {
        await this.$http.get('electric-card-info/getOperationRecordsList', {
          params: this.caozuojiluInfo
        }).then(resb => {
          this.$message({
            message: resb.data.code == 0 ? '获取在线卡用户操作记录成功！' : '在线卡用户操作记录为空',
            type: resb.data.code !== 0 ? 'error' : 'success'
          });
          if (resb.data.code !== 0) return;
          this.caozuojiluList = resb.data.data;
          this.caozuojiluTotal = resb.data.count;
        })
      }
    },
    change_input(e) {
      this.num = 0;
      console.log(this.onAccountForm, '414');
      if (e == 1) {
        this.btn_title = '退款'
      } else {
        this.btn_title = '充值'
      }
    },
    // 充值\退款事件
    cz_tk_dialoag_ok() {
      if (this.num < 1) {
        return this.$message({
          message: '充值或退款金额必须大于1',
          type: 'warning'
        })
      }
      // 充值
      if (this.radio == 0) {
        this.$http.post('electric-card-info/updRechargeMoneyById?id=' + this.select_ids + '&money=' + this.num * 100 + '&adminId=' + Number(window.sessionStorage.getItem('adminId'))).then(res => {
          if (res.data.code !== 0) { this.$message.error(res.data.msg) } else {
            this.$message.success('在线卡充值成功！');
            this.getOnAccountList();
          };
          this.cz_tk_dialoag = false;
          this.radio = 0;
        })

      }
      if (this.radio == 1) {
        // 退款
        if (this.num * 100 > this.onAccountForm.money) {
          this.$message({
            message: '余额必须大于退款金额',
            type: 'warning'
          })
        } else {
          this.$http.post('electric-card-info/updateRefundMoneyById?id=' + this.select_ids + '&money=' + this.num * 100 + '&adminId=' + Number(window.sessionStorage.getItem('adminId'))).then(ress => {
            if (ress.data.code !== 0) { this.$message.error(ress.data.msg) }
            else {
              this.$message.success('在线卡退款成功！');
              this.getOnAccountList();
            }
            this.cz_tk_dialoag = false;
            this.radio = 0;
          })
          this.cz_tk_dialoag = false;
          this.radio = 0;
        }
      }
    },
    cz_tk_dialoagClosed() {
      this.cz_tk_dialoag = false;
    },
    async kajilu(row) {
      this.expendInfo.electricCardInfoId = '';
      this.caozuojiluInfo.physicalCode = '';
      this.expendList = [];
      this.expendTotal = 0;
      this.kajilu_dialoag = true;
      this.expendInfo.electricCardInfoId = row.id;
      this.expendInfo.physicalCode = row.physicalCode;
      this.caozuojiluInfo.physicalCode = row.id;
      this.expendInfo.page = 1;
      if (this.caozuojiluInfo.physicalCode.length == 0 || this.caozuojiluInfo.physicalCode == 0) return;
      const { data: res } = await this.$http.get('electric-card-info/getRecordsList', {
        params: this.expendInfo
      })
      if (res.code !== 0) { row.k_j_l_anniu = false; this.$message.error(res.msg) } else {
        row.k_j_l_anniu = true;
        this.$message.success('获取在线卡用户消费列表成功！');
        this.expendList = res.data;
        this.expendTotal = res.count
      };
    },
    kajilu_dialoagClosed() {
      this.kajilu_dialoag = false;
      this.ids_all = 0;
    },
    async cz_tk(row) {
      this.num = 0;
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + row.id)
      if (res.code !== 0) return row.c_z_t_k_anniu = false;
      row.c_z_t_k_anniu = true;
      this.onAccountForm = res.data;
      this.select_ids = row.id;
      this.cz_tk_dialoag = true
    },
    async change_type(row) {
      this.onAccountForm.id = row.id;
      this.onAccountForm.electricCardState = row.electricCardState == true ? 1 : 0;
      await this.$http.post(`electric-card-info/updateStatusById/${row.id}/${this.onAccountForm.electricCardState}`).then(res => {
        this.$message({
          message: res.data.msg,
          type: res.data.code == 0 ? 'success' : 'error'
        })
        if (res.data.code !== 0) return;
        this.getOnAccountList()
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    handleSelectionChange() { },
    changeTime(value) {
      if (value) {
        this.queryInfo.startTime = '';
        this.queryInfo.endTime = '';
        this.queryInfo.startTime = value[0]
        this.queryInfo.endTime = value[1]
      } else {
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.getOnAccountList()
      }
    },
    // 获取在线卡用户列表
    async getOnAccountList() {
      const { data: res } = await this.$http.get('electric-card-info/getAllElectricCardInfo', { params: this.queryInfo })
      if (res.code !== 0) {
        this.$message.error(res.msg)
      }
      this.onAccountList = res.data;
      this.onAccountList.forEach(item => {
        item.k_j_l_anniu = false; item.b_j_anniu = false; item.c_z_t_k_anniu = false
      });
      this.onAccountList.forEach(item => {
        item.money = item.money / 100;
        if (item.electricCardState == 1) {
          item.electricCardState = true
        } else {
          item.electricCardState = false
        }
      })
      this.total = res.count
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getOnAccountList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getOnAccountList()
    },
    // 展示在线卡用户编辑对话框
    async showOnAccountDialog(row) {
      this.editDialogVisible = true
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + row.id)
      if (res.code !== 0) {
        row.b_j_anniu = false
        this.$message.error(res.msg)
      } else {
        row.b_j_anniu = true; this.onAccountForm = res.data
      }
    },
    // 编辑在线卡用户事件
    editOnlineAccount() {
      this.$refs.onAccountFormRef.validate(async valid => {
        if (!valid) return
        this.onAccountForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        const { data: res } = await this.$http.post('electric-card-info/updElectricCardInfo', this.onAccountForm)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('编辑在线卡用户成功！')
        this.getOnAccountList()
        this.editDialogVisible = false
      })
    },
    // 编辑在线卡用户对话框关闭事件
    editDialogClosed() {
      this.$refs.onAccountFormRef.resetFields();
      this.editDialogVisible = false;
      this.onAccountList.forEach(item => {
        item.b_j_anniu = false;
      })
    },
    // 监听pageSize改变的事件
    expendSizeChange(newSize) {
      this.expendInfo.limit = newSize;
      this.getExpendList()
    },
    // 监听页码值改变的事件
    expendCurrentChange(newPage) {
      this.expendInfo.page = newPage
      this.getExpendList()
    },
    // 展示消费列表对话框
    showExpend(row) {
      this.expendDialogVisible = true;
      this.expendInfo.electricCardInfoId = row.id;
      this.ids = row.id;
      this.getExpendList()
    },
    async getExpendList() {
      const { data: res } = await this.$http.get('electric-card-info/getRecordsList', {
        params: this.expendInfo
      })
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res)
      this.$message.success('获取在线卡用户消费列表成功！')
      this.expendList = res.data
      this.expendTotal = res.count
    },
    // 消费列表关闭事件
    expendDialogClosed() {
      this.expendDialogVisible = false
      this.expendList = []
    },
    // 展示在线卡充值对话框
    async showPresent(id) {
      this.select_ids = id;
      this.editPresentDialogVisible = true;
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + this.select_ids);
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      console.log(res.data)
      this.onAccountForm = res.data
    },
    // 展示在线卡退款对话框
    async showRefund(id) {
      this.select_ids = id;
      this.editRefundDialogVisible = true
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + id)
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.onAccountForm = res.data
    },
    // 在线卡充值对话框关闭事件
    editPresentDialogClosed() {
      this.num = 0
    },
    // 在线卡退款对话框关闭事件
    editRefundDialogClosed() {
      this.num = 0
    },
    // 操作记录
    async caozuojilu(id) {
      this.caozuojiluVisible = true;
      this.caozuojiluInfo.physicalCode = id;
      this.caozuojiluInfo.page = 1;
      this.getcaozuojiluList()
    },
    async getcaozuojiluList() {
      const { data: res } = await this.$http.get('electric-card-info/getOperationRecordsList', {
        params: this.caozuojiluInfo
      });
      this.caozuojiluList = res.data;
      this.caozuojiluTotal = res.count;
    },
    caozuojiluClosed() {
      this.caozuojiluVisible = false
      this.caozuojiluList = []
    },
    // 监听pageSize改变的事件
    caozuojiluSizeChange(newSize) {
      this.caozuojiluInfo.limit = newSize;
      this.getcaozuojiluList()
    },
    // 监听页码值改变的事件
    caozuojiluCurrentChange(newPage) {
      this.caozuojiluInfo.page = newPage;
      this.getcaozuojiluList()
    },
  }
}
</script>

<style lang="less" scoped></style>