<template>
  <div>
    <el-dialog title="电站列表" :visible.sync="flaging" width="30%" :before-close="icon_click">
      <el-form ref="elerc_form" label-width="80px">
        <el-form-item>
          <el-checkbox-group v-model="ntrs" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in elerc_form.type" :label="item.id" :key="item.id" :disabled="item.disabled">{{
      item.stationName
    }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm">取 消</el-button>
        <el-button type="primary" v-debounce="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20" style="margin-bottom: 20px;">
        <el-col :span="3">
          <el-input placeholder="请输入登录名称" v-model="queryInfo.userName" clearable @clear="getUserList" @change="current">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getUserList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <el-col :span="2" v-if="caozuoquanxian">
          <el-button type="success" icon="el-icon-plus" @click="showAddDialog(0)">添加用户</el-button>
        </el-col>
        <el-col :span="1" v-if="caozuoquanxian">
          <el-button @click="chongzhimima" style="background-color: rgb(152, 216, 236);color:#fff;">重置密码</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table ref="multipleTable" :data="userList" border stripe @selection-change="handleSelectionChange"
        empty-text="暂无数据">
        <el-table-column type="selection" width='55' :selectable='selectable'></el-table-column>
        <el-table-column align="center" prop="id" label="登录ID" width="120"></el-table-column>
        <el-table-column align="center" prop="username" label="登录名称" width="200">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: flex-start;">
              {{ scope.row.username }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="realname" label="真实姓名" width="280">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: flex-start;">
              {{ scope.row.realname }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="phone" label="电话" width="120"></el-table-column>
        <el-table-column align="center" prop="roleName" label="角色名称" width="140"></el-table-column>
        <el-table-column align="center" prop="des" label="描述">
          <template slot-scope="scope">
            <span v-if="scope.row.des !== null">
              {{ scope.row.des }}
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" v-if="caozuoquanxian" width="80">
          <template slot-scope="scope">
            <el-switch active-color="#13ce66" inactive-color="#ff4949" v-model="scope.row.status"
              @change="userStateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="400">
          <template slot-scope="scope">
            <!-- 电站详情 -->
            <!-- 修改按钮 -->
            <el-button type="primary" icon="el-icon-edit" size="mini" :disabled="scope.row.b_j_anniu"
              @click="showAddDialog(scope.row)" v-if="scope.row.caozuoquanxian">编辑</el-button>
            <!-- 删除按钮 scope.row.-->
            <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="scope.row.s_c_anniu"
              @click="removeUserById(scope.row)" v-if="scope.row.caozuoquanxian">删除</el-button>
            <el-button type="warning" icon="el-icon-setting" size="mini" :disabled="scope.row.d_z_l_b_anniu"
              @click="eletrc_station(scope.row)" v-if="scope.row.caozuoquanxian">可查看电站列表</el-button>
            <!-- 分配角色按钮 -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加&编辑用户的对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible" width="35%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域  :rules="editFormRules"-->
      <el-form :model="editForm" ref="editFormRef" label-width="100px" :rules="editFormRules">
        <el-form-item label="登录名称" prop="username">
          <el-input placeholder="请输入登录名称" v-model="editForm.username" :disabled="admin_user"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password" v-if="admin_login">
          <el-input placeholder="请输入登录密码" v-model="editForm.password"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realname">
          <el-input placeholder="请输入真实姓名" v-model="editForm.realname"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input placeholder="请输入手机号码" v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="des">
          <el-input placeholder="请输入你的备注信息" v-model="editForm.des"></el-input>
        </el-form-item>
        <el-form-item label="选择角色" prop="selectedRoleId">
          <el-select v-model="selectedRoleId" placeholder="请选择角色" style="width: 100%;" @change="change" filterable>
            <el-option v-for="item in rolesList" :key=item.selectedRoleId :label="item.name"
              :value="item.selectedRoleId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="重置密码" :visible.sync="chongzhimimaVisible" width="35%" @close="chongzhimimaClosed" append-to-body
      :close-on-click-modal="false">
      <el-form label-width="100px">
        <el-form-item label="用户名" required>
          <el-select v-model="realname" placeholder="请选择" label="用户名" filterable>
            <el-option v-for="item in role_list" :key="item.id" :label="item.realname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新密码" required>
          <el-input placeholder="请输入新密码" v-model="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chongzhiform_qx">取 消</el-button>
        <el-button type="primary" @click="chongzhiform_ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  <!-- 用户管理 -->
</template>
<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'usersWorld',
  data() {
    // 验证用户名称的规则
    const checkUserName = (rule, value, callback) => {
      const regUserName = /^\w+$/
      if (regUserName.test(value)) {
        return callback()
      }
      callback(new Error('用户名称由数字、26个英文字母或者下划线组成'))
    }
    // 验证真实姓名的规则 
    const checkRealName = (rule, value, callback) => {
      const reg = /^(?=.{2,4}$)[\u4e00-\u9fa5]+$/
      if (reg.test(value)) {
        return callback()
      }
      callback(new Error('请输入2-4个汉字'))
    }
    // 验证登陆密码的规则 
    const validatePass = (rule, value, callback) => {
      const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/
      if (value == '' || value == undefined || value == null) {
        callback(new Error('请输入密码'))
      } else if (!reg.test(value)) {
        callback(new Error('密码应在6到20位之间,包含字母、数字'))
      } else {
        callback()
      }
    }
    // 验证手机号的规则
    const checkMobile = (rule, value, callback) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        // 正确的电话号码
        return callback()
      }
      callback(new Error('请输入正确的手机号码'))
    }
    const validateUser = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_]{4,16}$/
      if (value == '' || value == undefined || value == null) {
        callback(new Error('请输入账号'))
      } 
      // else if (!reg.test(value)) {
      //   callback(new Error('账号应在4到16位之间,包含字母、数字或下划线'))
      // } 
      else {
        callback()
      }
    }
    return {
      editFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { validator: validateUser, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '真实姓名不能为空', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: '纬度不能为空', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'change' }
        ]
      },
      bianji_id: 0,
      moren: [],
      caozuoquanxian: false,
      chongzhimimaVisible: false,
      shanchuquanxian: false,
      chongzhiform: [],
      role_list: [],
      isIndeterminate: false,
      hu: false,
      eck: false,
      arr: [],
      elerc_form: {
        type: []
      },
      station_list: [],
      admin_user: true,
      admin_login: true,
      flaging: false,
      // 获取用户列表的参数对象
      queryInfo: {
        userName: '',
        type: null,
        adminId: window.sessionStorage.getItem('adminId'),
        // 当前展示的页码数
        page: 1,
        // 当前每页显示的数据条数
        limit: 8
      },
      total: 0,
      title: '',
      userList: [],
      // 控制添加用户对话框的显示与隐藏
      editDialogVisible: false,
      // 添加用户的表单数据
      editForm: {
        username: null,
        password: null,
        roleId: null,
        roleName: null,
        selectedRoleId: null,
        parentId: window.sessionStorage.getItem('adminId'),
        picId: 0,
        realname: null,
        phone: null,
        // areaCode: null,
        status: 1,
        // type: 0,
        isApp: 0,
        id: null,
        des: ''
      },
      // 添加表单的验证规则对象
      // editFormRules: {
      //   username: [
      //     { validator: checkUserName, required: true, message: '登录名称不能为空', trigger: 'blur' },
      //   ],
      //   realname: [
      //     { validator: checkRealName, required: true, message: '真实姓名不能为空', trigger: 'blur' },
      //   ],
      //   password: [
      //     { validator: validatePass, required: true, message: '密码不能为空', trigger: 'blur' },
      //   ],
      //   phone: [
      //     { validator: checkMobile, required: true, message: '电话号码不能为空', trigger: 'blur' },
      //   ]
      // },
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      // 需要被分配角色的用户信息
      userInfo: {},
      // 所有角色的数据列表
      rolesList: [],
      // 已选中的角色id值
      selectedRoleId: null,
      provinceData: [],
      cityData: [],
      districtData: [],
      addressCounty: {
        provinceId: '',
        provinceName: '',
        cityId: '',
        cityName: '',
        districtId: '',
        districtName: ''
      },
      // strArr: [],
      typeList: [],
      ids: '',
      he: '',
      ntrs: null,
      ids: null,
      row_id: null,
      realname: null,
      password: '',
      str: [],
      arr: [],
      newarr: []
    }
  },
  created() {
    caozuo(122).then(res => {
      this.caozuoquanxian = res;
    });
    this.getUserList();
    this.userNames()
  },
  watch: {
    title: {
      handler(newvalue) {
        if (newvalue == '添加用户') {
          this.admin_login = true;
          this.admin_user = false;
          this.editForm.password = ''
        } else {
          this.admin_login = false;
          this.admin_user = true;
          this.editForm.password = ''
        }
      }
    }
  },
  methods: {
    current() {
      this.queryInfo.page = 1;
    },
    // 获取所有用户登录名称（更换密码）
    async userNames() {
      let abc = []; let cdn = [];
      const { data: res } = await this.$http.get('admin/getAllAdmin', {
        params: {
          page: 1,
          limit: 50,
          adminId: window.sessionStorage.getItem('adminId')
        }
      })
      if (res.code == 0) {
        res.data.forEach(item => {
          abc.push({
            id: item.id,
            realname: item.realname
          })
        })
        this.role_list = abc;
      }
    },
    // 重置密码取消
    chongzhiform_qx() {
      this.realname = '';
      this.password = '';
      this.chongzhimimaVisible = false;
    },
    // 重置密码确定事件
    async chongzhiform_ok() {
      await this.$http.post('admin/restPassWord?id=' + this.realname + '&passWord=' + this.password + '&adminId=' + Number(window.sessionStorage.getItem('adminId'))).then(res => {
        this.chongzhimimaVisible = false;
        if (res.data.code != 0) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.success(res.data.msg);
        }
      })
    },
    chongzhimimaClosed() {
      this.chongzhimimaVisible = false;
    },
    chongzhimima() {
      this.passWord = '';
      this.realname = '';
      this.chongzhimimaVisible = true;
    },
    handleCheckedCitiesChange(value) {
      this.ntrs = [];
      this.ntrs = value;
    },
    selectable(row, index) {
      if (row.id == this.he) {
        return true
      } else if (row.id == this.row_id) {
        return true
      }
      else {
        return false
      }
    },
    icon_click() {
      this.flaging = false;
    },
    // 电站详情
    async eletrc_station(row) {
      this.elerc_form.type = [];
      this.moren = [];
      this.ids = row.id;
      this.ntrs = [];
      this.newarr = [];
      // // 所有已选ID
      await this.$http.get('admin/getAllAdminStationIds').then(res => {
        this.str = res.data.data.split(',');
      });
      await this.$http.get('station-info/getStationInfoList', {
        params: {
          page: 1,
          limit: 100,
          stationMode:"0,1,2"
        }
      }).then(resb => {
        this.flaging = true;
        resb.data.data.forEach((item) => {
          this.moren.push({
            id: item.id,
            stationName: item.stationName,
            checked: false,
          });
        });
      });
      if (row.roleId !== 5 || row.roleId !== 1) {
        this.moren.push({
          disabled: true
        });
        this.elerc_form.type = this.moren;
      } else {
        this.moren.push({
          disabled: false
        });
        this.elerc_form.type = this.moren;
        for (let i = 0; i < this.elerc_form.type.length; i++) {
          for (let j = 0; j < this.str.length; j++) {
            if (this.elerc_form.type[i].id == this.str[j]) {
              this.elerc_form.type[i].disabled = true;
            }
          }
        };
      };
      await this.$http.get('admin/getAdminById/' + this.ids).then(res => {
        if (res.data.data.stationIds != null) {
          this.ntrs = res.data.data.stationIds.split(',');
          this.arr = res.data.data.stationIds.split(',');
          this.ntrs.forEach((item, index) => {
            this.ntrs[index] = parseInt(this.ntrs[index])
          });
          if (window.sessionStorage.getItem('roleId') == 5) {
            for (let i = 0; i < this.elerc_form.type.length; i++) {
              for (let j = 0; j < this.ntrs.length; j++) {
                if (this.elerc_form.type[i].id == this.ntrs[j]) {
                  this.elerc_form.type[i].disabled = false;
                }
              }
            }
          }
          this.flaging = true;
        }
      });
    },
    resetForm() {
      this.ntrs = [];
      this.$message('撤回所选择的电站')
      this.flaging = false;
    },
    // 电站详情修改
    onSubmit() {
      let a = this.ntrs;
      if (a.length > 0) {
        let e = ''; let stationId = '';
        a.forEach(item => {
          e += item + ','
        })
        stationId = e;
        stationId = stationId.substring(0, stationId.length - 1);
        this.$http.post('/admin/updateStationIdsById', {
          id: this.ids,
          stationIds: stationId
        }).then(res => {
          if (res.data.code == 0) {
            this.$message.success(res.data.msg)
            this.flaging = false;
          } else {
            this.$message.error(res.data.msg)
          }
        });
      } else {
        this.$message.error('最少选择一个电站')
      }
    },
    // 获取选中角色的id
    change(value) {
      this.editForm.selectedRoleId = Number(value)
    },
    typeChange(value) {
      this.editForm.type = value
    },
    // 获取用户的列表
    async getUserList() {
      this.userList = [];
      const { data: res } = await this.$http.get('admin/getAllAdmin', { params: this.queryInfo })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.userList = res.data;
      // this.userList.push({ b_j_anniu: false, d_z_l_b_anniu: false, s_c_anniu: false });
      for (let index = 0; index < this.userList.length; index++) {
        const element = this.userList[index];
        element.disabled = false;
        element.shanchuquanxian = false;
      };
      this.userList.forEach(item => {
        item.status == 0 ? item.status = true : item.status = false;
        item.b_j_anniu = false; item.d_z_l_b_anniu = false; item.s_c_anniu = false;
        if (window.sessionStorage.getItem('roleId') == item.roleId && window.sessionStorage.getItem('roleId') != 1) {
          item.disabled = true;
          item.caozuoquanxian = false;
        }
        else if (window.sessionStorage.getItem('roleId') < item.roleId) {
          item.disabled = false;
          item.caozuoquanxian = true;
        }
        else if (window.sessionStorage.getItem('roleId') == 1) {
          item.caozuoquanxian = true;
        }
      });
      this.total = res.count;

    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getUserList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getUserList()
    },
    // 用户状态的更新事件
    async userStateChange(userInfo) {
      if (userInfo.status == true) {
        this.editForm.status = 0
      } else if (userInfo.status == false) {
        this.editForm.status = 1
      }
      const { data: res } = await this.$http.post(`admin/updateStatusById/${userInfo.id}/${this.editForm.status}`)
      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else {
        this.$message.success(res.msg)
      }
      this.getUserList()
    },
    // 展示添加、编辑用户的对话框
    async showAddDialog(row) {
      this.editDialogVisible = true;
      this.getRolesList()
      if (row == 0) {
        this.title = '添加用户';
      } else {
        this.title = '编辑用户'
        this.bianji_id = row.id;
        const { data: res } = await this.$http.get('admin/getAdminById/' + row.id)
        if (res.code == 0) {
          row.b_j_anniu = true;
          this.editForm = res.data;
          this.selectedRoleId = res.data.roleId;
        } else {
          row.b_j_anniu = false;
        }

      }
    },
    // 点击按钮，添加&编辑新用户（确定事件）
    addUser() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        this.editForm.roleId = this.selectedRoleId;
        this.editForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        this.editForm.isApp === true ? this.editForm.isApp = 1 : this.editForm.isApp = 0
        if (this.title === '添加用户') {
          if (this.editForm.selectedRoleId != null) {
            this.editForm.status = 0;
            const { data: res } = await this.$http.post('admin/addAdmin', this.editForm)
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message.success('添加用户成功！')
            this.editDialogVisible = false
            this.getUserList()
          } else {
            this.$message.error('请选择角色，此为必选项')
          }
        } else {
          const { data: res } = await this.$http.post('admin/updateAdminById', this.editForm)
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('编辑用户成功！')
          this.editDialogVisible = false
          this.getUserList()
        }
      })
    },
    // 监听添加&编辑用户对话框的关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
      this.editDialogVisible = false;
      this.userList.forEach(item => {
        item.b_j_anniu = false
      });
      this.selectedRoleId = null
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      console.log(value, '431');
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 根据id删除对应用户信息
    async removeUserById(row) {
      this.he = row.id;
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult === 'cancel') {
        return this.$message.info('已取消删除')
      }
      if (row.id == null || row.id == '' || row.id == undefined) {
        const { data: res } = await this.$http.post('admin/delAdminById/' + this.ids + '/' + Number(window.sessionStorage.getItem('adminId')))
        if (res.code !== 0) {
          row.s_c_anniu = false;
          this.$message.error('删除用户失败！')
        } else {
          row.s_c_anniu = true;
          this.$message.success('删除用户成功！')
          this.getUserList()
        }
      } else {
        const { data: res } = await this.$http.post('admin/delAdminById/' + row.id.toString() + '/' + Number(window.sessionStorage.getItem('adminId')))
        if (res.code !== 0) {
          row.s_c_anniu = false;
          this.$message.error('删除用户失败！')
        } else {
          row.s_c_anniu = true;
          this.$message.success('删除用户成功！')
          this.getUserList()
        }
      }
    },
    // 重置搜索条件
    chongzhi() {
      this.queryInfo = {
        userName: '',
        type: null,
        adminId: Number(window.sessionStorage.getItem('adminId')),
        // 当前展示的页码数
        page: 1,
        // 当前每页显示的数据条数
        limit: 8
      };
      this.getUserList();
    },
    // 获取所有的角色列表
    getRolesList() {
      this.rolesList = [];
      this.$http.get('adminRole/getAllAdminRole', {
        params: {
          page: 1,
          limit: 100,
          status: 0,
          name: ''
        }
      }).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.msg)
        } else {
          res.data.data.forEach(item => {
            this.rolesList.push({
              name: item.rolename,
              selectedRoleId: item.id
            })
          })
        }
      })
    },
    // 展示分配角色的对话框
    setRoles(userInfo) {
      this.userInfo = userInfo
      this.setRoleDialogVisible = true
      this.getRolesList()
    },
    // 点击按钮分配角色
    async saveRoleInfo() {
      if (!this.selectedRoleId) {
        return this.$message.error('请选择要分配的角色！')
      }
      const formData = new FormData()
      formData.append('id', this.userInfo.id)
      formData.append('roleId', this.selectedRoleId)
      const { data: res } = await this.$http.post('admin/updateRoleById', formData)
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('分配角色成功！')
      this.getUserList()
      this.setRoleDialogVisible = false
    }
  }
}
</script>
