<template>
    <!-- 卡片视图区域 -->
    <el-card class="card">
        <el-form class="form" :inline="true" :model="queryInfo">
            <div>
                <el-form-item label="订单编号：">
                    <el-input placeholder="请输入订单号" @change="current" clearable @keyup.enter.native="getPowerStationList"
                        v-model="queryInfo.billCode" @clear="getPowerStationList">
                    </el-input>
                </el-form-item>
                <el-form-item label="充电桩桩号：">
                    <el-input placeholder="请输入电桩编号" @change="current" clearable
                        @keyup.enter.native="getPowerStationList" v-model="queryInfo.pileCode"
                        @clear="getPowerStationList">
                    </el-input>
                </el-form-item>
                <el-form-item label="用户(卡号/手机号)：">
                    <el-input placeholder="请输入用户(卡号/手机号)" clearable @change="current"
                        @keyup.enter.native="getPowerStationList" v-model="queryInfo.icCode"
                        @clear="getPowerStationList">
                    </el-input>
                </el-form-item>
                <el-form-item label="订单日期：">
                    <el-date-picker v-model="values" @blur="pickBlur" :picker-options="pickerOptions" type="daterange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm" @change="changeTime">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="chaxun">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
                </el-form-item>
            </div>
        </el-form>
        <el-table class="table" :data="chargingList" border @row-dblclick="dbclick" :lazy="true" empty-text="暂无数据">
            <el-table-column align="center" type="expand">
                <template slot-scope="scope">
                    <el-form label-position="left" class="form">
                        <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                            <el-col :span="6">
                                <el-form-item label="订单编号：" prop="billCode">
                                    <span v-if="scope.row.startType == 5">{{ scope.row.billCode }}</span>
                                    <span v-else>{{ time_all(scope.row) }}</span>
                                    <span v-if="scope.row.billCode == null">--</span>
                                </el-form-item>
                                <el-form-item label="电桩编号：" prop="pileCode">
                                    <span>{{ scope.row.pileCode }}</span>
                                </el-form-item>
                                <el-form-item label="卡号：">
                                    <span v-if="scope.row.icCode" style="margin-left: 16px;">{{ scope.row.icCode
                                        }}</span>
                                    <span v-else style="margin-left: 16px;"> -- </span>
                                </el-form-item>
                                <el-form-item label="设备类型：">
                                    <el-tag>
                                        {{ scope.row.pileType == 0 ? '无疆交流桩' : scope.row.pileType == 1 ? '光法直流桩' :
            scope.row.pileType ==
                2 ? '光法交流桩' : scope.row.pileType == 3 ? '锐创低速桩' : scope.row.pileType == 4 ?
                    '简充直流桩' :
                    scope.row.pileType ==
                        5 ? '简充交流桩' : '其他充电桩' }}
                                    </el-tag>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="车架号：">
                                    <span v-if="scope.row.carvin">{{ scope.row.carvin }}</span>
                                    <span v-else> -- </span>
                                </el-form-item>
                                <el-form-item label="优惠服务费（元）：" prop="servicemoneygive">
                                    <span>￥{{ junzhi(scope.row.servicemoneygive) }}</span>
                                </el-form-item>
                                <el-form-item label="优惠电费（元）：" prop="chargemoneygive">
                                    <span>￥{{ junzhi(scope.row.chargemoneygive) }}</span>
                                </el-form-item>
                                <el-form-item label="优惠总费用（元）：" prop="billMoneygive">
                                    <span>￥{{ junzhi(scope.row.billMoneygive) }}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <!-- 进度条 -->
                                <el-form-item label="开始SOC：">
                                    <el-progress :text-inside="true" :stroke-width="20" :percentage="scope.row.startSoc"
                                        status="success"></el-progress>
                                </el-form-item>
                                <!-- 进度条 -->
                                <el-form-item label="当前SOC：">
                                    <el-progress :text-inside="true" :stroke-width="20" :percentage="scope.row.nowSoc"
                                        status="success"></el-progress>
                                </el-form-item>
                                <el-form-item label="充电时长（分钟）：" prop="lengthTime">
                                    <span>{{ time_min(scope.row.lengthTime) }}分钟</span>
                                </el-form-item>
                                <el-form-item label="结束原因：" prop="stopReason"> <span>{{ scope.row.stopReason }}</span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="尖时电量（度）：">
                                    <span v-if="scope.row.echg1">{{ scope.row.echg1 }}度</span>
                                    <span v-else> 0度</span>
                                </el-form-item>
                                <el-form-item label="峰时电量（度）：">
                                    <span v-if="scope.row.echg2">{{ scope.row.echg2 }}度</span>
                                    <span v-else> 0度</span>
                                </el-form-item>
                                <el-form-item label="平时电量（度）：">
                                    <span v-if="scope.row.echg3">{{ scope.row.echg3 }}度</span>
                                    <span v-else> 0度</span>
                                </el-form-item>
                                <el-form-item label="谷时电量（度）：">
                                    <span v-if="scope.row.echg4">{{ scope.row.echg4 }}度</span>
                                    <span v-else> 0度</span>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column align="center" label="充电车主" prop="icCode"></el-table-column>
            <el-table-column align="center" label="电桩编号" prop="pileCode">
                <template slot-scope="scope">
                    <span>{{ scope.row.pileCode }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="枪号" prop="gunNumber">
                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.gunNumber == 1 ? '' : scope.row.gunNumber == 2 ? 'warning' : scope.row.gunNumber == 3 ? 'success' : 'error'">
                        {{ scope.row.gunNumName }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="开始时间" prop="startTime">
                <template slot-scope="scope">
                    <span v-if="scope.row.phone.startTime == null">--</span>
                    <span v-else>{{ scope.row.startTime }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="结束时间" prop="endTime">
                <template slot-scope="scope">
                    <span v-if="scope.row.phone.endTime == null">--</span>
                    <span v-else>{{ scope.row.endTime }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="充电SOC" prop="endSoc">
                <template slot-scope="scope">
                    <span>{{ scope.row.endSoc }}%</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="电量（度）" prop="totalKwh">
                <template slot-scope="scope">
                    <span>{{ scope.row.totalKwh }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="优惠电量（度）" prop="chargegive">
                <template slot-scope="scope">
                    <span>{{ scope.row.chargegive }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="电费|服务费（元）">
                <template slot-scope="scope">
                    ￥<span>{{ junzhi(scope.row.chargemoney) }}</span>&emsp;|
                    ￥<span>{{ junzhi(scope.row.servicemoney) }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="总费用（元）" prop="billMoney">
                <template slot-scope="scope">
                    <span>￥{{ junzhi(scope.row.billMoney) }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="启动方式" prop="startType" width="80">
                <template slot-scope="scope">
                    <img style="width: 50px;
           height: 50px;"
            :src="scope.row.startType == 0 || scope.row.startType == 9 ? tupian + '小程序.png' : scope.row.startType == 1 ? tupian + '在线卡.png' : scope.row.startType == 2 ? tupian + '离线卡.png' : scope.row.startType == 3 ? tupian + '管理卡.png' : scope.row.startType == 4 ? tupian + 'Vin.png' : scope.row.startType == 5 ? tupian + 'hlht.png' : scope.row.startType == 6 ? tupian + 'zfb.png' :
             scope.row.startType == 9 ? tupian + 'app.png':tupian + 'ocpp_App.png'"
            alt="">
                </template>
            </el-table-column>
            <el-table-column align="center" label="工作状态" prop="gunState" width="120">
                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.gunState == 0 ? '' : scope.row.gunState == 1 ? 'warning' : scope.row.gunState == 2 ? 'success' : 'error'">
                        {{ scope.row.gunState == 0 ? '空闲中' : scope.row.gunState == 1 ? '准备开始' :
            scope.row.gunState ==
                2 ? '正在充电' : scope.row.gunState == 3 ? '充电结束' : scope.row.gunState == 4 ? '启动失败'
                    :
                    scope.row.gunState ==
                        5 ? '预约状态' : '系统故障' }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="是否支付" prop="payState">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.payState == 0 ? 'info' : 'success'">{{ scope.row.payTypeName
                        }}</el-tag>
                </template>
            </el-table-column>
            <!-- 未知 -->
            <!-- <el-table-column align="center" label="更新时间" prop="updateTime"></el-table-column> -->
        </el-table>
        <!-- 分页导航区域 -->
        <el-pagination @size-change="sizeCurrentChange" @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]" :current-page="queryInfo.page" :page-size="queryInfo.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
    </el-card>
</template>

<script>
export default {
    name: 'chargingWorld',
    data() {
        return {
            pickerMinDate: '',
            pickerOptions: {
                onPick: obj => {
                    this.pickerMinDate = new Date(obj.minDate).getTime()
                    const start = this.formatDate(obj.minDate, 'start')
                    const end = this.formatDate(obj.maxDate, 'end')
                    obj.maxDate && (this.values = [start, end])
                },
                disabledDate: time => {
                    if (this.pickerMinDate) {
                        const oneMonth = 1000 * 3600 * 24 * 31
                        const maxTime = this.pickerMinDate + oneMonth
                        const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
                        const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
                        return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
                    }
                }
            },
            page_num: 0,
            values: ['', ''],
            tupian: this.$imgUrl,
            // 查询充电订单列表所需参数
            queryInfo: {
                billCode: null,
                pileCode: null,
                startTime: null,
                endTime: null,
                page: 1,
                limit: 8,
                icCode: null,
            },
            // 充电订单数据
            chargingList: [],
            total: 0,
        }
    },
    watch: {
        chargingList: {
            handler(newval, oldval) {
                this.chargingList = newval
            }
        }
    },
    computed: {
        time_all() {
            return function (val) {
                const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
                return hr
            }
        },
        time_min() {
            return function (val) {
                return (val / 60).toFixed(2)
            }
        },
        junzhi() {
            return function (val) {
                return (val / 100).toFixed(2)
            }
        },
    },
    methods: {
        current() {
            this.queryInfo.page = 1;
        },
        pickBlur() {
            this.pickerMinDate = ''
        },
        formatDate(datetime, type) {
            const time = new Date(datetime)
            const year = time.getFullYear()
            const month = (time.getMonth() + 1).toString().padStart(2, '0')
            const date = (time.getDate()).toString().padStart(2, '0')
            return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
        },
        getDate() {
            const start = new Date()
            this.values[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
            this.values[1] = this.formatDate(start.getTime(), 'end')
        },

        // 双击展开折叠
        dbclick(row, column, event) {
            // console.log(event.currentTarget.querySelector(".el-table__expand-icon"))
            if (event.currentTarget.querySelector(".el-table__expand-icon")) {
                event.currentTarget.querySelector(".el-table__expand-icon").click()
            }
        },
        chaxun() {
            if (this.queryInfo.billCode !== null || this.queryInfo.pileCode !== null || this.queryInfo.endTime !== null || this.queryInfo.startTime !== null) {
                this.getPowerStationList()
            }
        },
        // 重置搜索条件
        chongzhi() {
            this.queryInfo = {
                billCode: null,
                pileCode: null,
                startTime: null,
                endTime: null,
                page: 1,
                limit: 8,
                icCode: null,
            };
            this.values = [];
            this.pickerMinDate = '';
            this.getPowerStationList();
        },
        changeTime(value) {
            this.queryInfo.page = 1;
            if (value) {
                this.queryInfo.startTime = value[0];
                this.queryInfo.endTime = value[1];
                if (this.queryInfo.startTime == this.queryInfo.endTime) {
                    this.$message.error('起始时间不得为同一天！');
                    this.queryInfo.startTime = ''
                    this.queryInfo.endTime = ''
                }
            } else {
                this.queryInfo.startTime = null
                this.queryInfo.endTime = null
                this.pickerMinDate = '';
            }
        },
        // 监听页码值改变的事件
        handleCurrentChange(newPage) {
            this.queryInfo.page = newPage;
            this.getPowerStationList()
        },
        sizeCurrentChange(newPage) {
            this.queryInfo.limit = newPage;
            this.getPowerStationList()
        },
        // 获取电站列表数据
        async getPowerStationList() {
            this.chargingList = [];
            this.powerStationList = [];
            await this.$http.get('old-bill/getAllOldBill', { params: this.queryInfo }).then(res => {
                if (res.data.code != 0) {
                    this.queryInfo.billCode = null;
                    this.queryInfo.pileCode = null;
                    this.$message.error('暂无数据');
                } else {
                    this.chargingList = res.data.data;
                    this.chargingList.forEach((item => {
                        if (item.protocolVersion == 11) {
                            item.echg1 = (item.echg1 / 10000).toFixed(2);
                            item.echg2 = (item.echg2 / 10000).toFixed(2);
                            item.echg3 = (item.echg3 / 10000).toFixed(2);
                            item.echg4 = (item.echg4 / 10000).toFixed(2);
                            item.totalKwh = (item.totalKwh / 10000).toFixed(2);
                            item.chargegive = (item.chargegive / 10000).toFixed(2);
                        } else {
                            item.echg1 = (item.echg1 / 100).toFixed(2);
                            item.echg2 = (item.echg2 / 100).toFixed(2);
                            item.echg3 = (item.echg3 / 100).toFixed(2);
                            item.echg4 = (item.echg4 / 100).toFixed(2);
                            item.totalKwh = (item.totalKwh / 100).toFixed(2);
                            item.chargegive = (item.chargegive / 100).toFixed(2);
                        };
                    }));
                    this.total = res.data.count;
                    this.$message.success(res.data.msg)
                }
            });
        },
    }
}
</script>

<style></style>
