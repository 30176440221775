<template>
  <div>
    <!-- 卡片视图区域 直充-->
    <el-card>
      <!-- style="margin-bottom:20px ;" -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :span="3" label="车牌号">
          <el-input placeholder="请输入车牌号" clearable v-model="queryInfo.carNumber" @clear="getVinAccountList"
            @change="current">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getVinAccountList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="success" icon="el-icon-plus" @click="showVinAccount">添加</el-button>
        </el-col>
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="vinAccountList" border @selection-change="handleSelectionChange" @row-dblclick="dbclick">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="8">
                  <el-form-item label="车架号：">
                    <span v-if="scope.row.carvin">{{ scope.row.carvin }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="车牌号：">
                    <span v-if="scope.row.carNumber">{{ scope.row.carNumber }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="所属公司：">
                    <span v-if="scope.row.companyName">{{ scope.row.companyName }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="更新时间：">
                    <span v-if="scope.row.updateTime">{{ scope.row.updateTime }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="场站编号" prop="stationName"></el-table-column>
        <el-table-column align="center" label="车架号" prop="carvin"></el-table-column>
        <el-table-column align="center" label="车牌编号" prop="carNumber"></el-table-column>
        <el-table-column align="center" label="自编号" prop="selfNumbering">
          <template slot-scope="scope">
            <span v-if="scope.row.selfNumbering !== null">
              {{ scope.row.selfNumbering }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="路别" prop="columns">
          <template slot-scope="scope">
            <span v-if="scope.row.columns !== null">
              {{ scope.row.columns }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="所属公司" prop="companyName"></el-table-column>
        <el-table-column align="center" label="状态" prop="electricCardState">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" :active-color="scope.row.type == false ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.type == false ? '#ff4949' : '#13ce66'"
              @change="change_type(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" v-if="caozuoquanxian">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
              @click="showVinAccount(scope.row)">编辑</el-button>
            <!-- <el-button size="small" type="danger" @click="removeById(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 添加&&编辑直充用户的对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible" width="35%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <el-form :model="vinAccountForm" :rules="vinAccountFormRules" ref="vinAccountFormRef" label-width="80px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="12">
            <!-- 内容主体区域 -->
            <el-form-item label="站点选择" prop="stationId" required>
              <el-select v-model="vinAccountForm.stationId">
                <el-option v-for="item in stationList" :key="item.id" :value="item.id"
                  :label="item.stationName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属公司" prop="companyName" required>
              <el-input v-model="vinAccountForm.companyName"></el-input>
            </el-form-item>
            <el-form-item label="路别：" prop="columns">
              <el-input v-model="vinAccountForm.columns"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车架号" prop="carvin" required>
              <el-input v-model="vinAccountForm.carvin"></el-input>
            </el-form-item>
            <el-form-item label="车牌号" prop="carNumber" required>
              <el-input v-model="vinAccountForm.carNumber"></el-input>
            </el-form-item>
            <el-form-item label="自编号：" prop="selfNumbering">
              <el-input v-model="vinAccountForm.selfNumbering"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editVinAccount">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'vinAccountWorld',
  data() {
    return {
      caozuoquanxian: false,
      // 获取vin用户列表所需参数
      queryInfo: {
        // vin: '',
        companyName: '',
        carNumber: '',
        // startTime: null,
        // endTime: null,
        stationId: null,
        page: 1,
        limit: 10,
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId')
      },
      // vin用户数据及参数
      vinAccountList: [],
      vinAccountForm: {
        id: null,
        // roadId: null,
        // carNum: null,
        companyName: null,
        power: null,
        userTime: null,
        engineNum: null,
        carvin: null,
        carNumber: null,
        companyId: null,
        powerStationId: null,
        electricCardState: 0,
        stationId: null,
        // adminId: window.sessionStorage.getItem('adminId'),
      },
      vinAccountFormRules: {},
      total: 0,
      title: '',
      value: '',
      editDialogVisible: false,
      // 电站数据
      powerStationOptions: [],
      // 电站所属公司数据
      companyOptions: [
        {
          value: 1,
          label: '公交一公司'
        },
        {
          value: 2,
          label: '公交二公司'
        },
        {
          value: 3,
          label: '公交三公司'
        },
        {
          value: 4,
          label: '公交四公司'
        },
        {
          value: 5,
          label: '焦作市龙源巴士'
        }
      ],
      powerOptions: [],
      ids: '',
      stationList: []
    }
  },
  created() {
    caozuo(100).then(res => {
      this.caozuoquanxian = res;
    });
    this.getVinAccountList()
  },
  methods: {
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        // vin: '',
        companyName: '',
        carNumber: '',
        // startTime: null,
        // endTime: null,
        stationId: null,
        page: 1,
        limit: 8,
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId')
      };
      this.getVinAccountList();
    },
    // 获取vin用户列表
    async getVinAccountList() {
      const { data: res } = await this.$http.get('charge-vin/getAllChargeVin', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.vinAccountList = res.data
      this.vinAccountList.forEach(item => {
        item.b_j_anniu = false;
      });
      this.vinAccountList.forEach(item => {
        if (item.electricCardState == 0) {
          item.type = true
        } else if (item.electricCardState == 1) {
          item.type = false
        }
      })
      this.total = res.count
    },
    async change_type(row) {
      this.vinAccountForm.id = row.id;
      // console.log(row.type,'208')
      if (row.type == false) {
        row.type = true;
        row.electricCardState = 1;
      } else {
        row.type = false;
        row.electricCardState = 0;
      };
      this.vinAccountForm.electricCardState = row.electricCardState;
      await this.$http.post(`charge-vin/updateStatusById/${row.id}/${this.vinAccountForm.electricCardState}`).then(res => {
        this.$message.success(res.data.msg)
        this.getVinAccountList();
      }).catch(res => {
        this.$message(res.data.msg)
      })
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getVinAccountList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getVinAccountList()
    },
    // 展示添加&编辑直充用户对话框
    async showVinAccount(row) {
      this.powerStationOptions = []
      this.editDialogVisible = true;
      let a = null;
      if (window.sessionStorage.getItem('stationIds') == null) {
        a = null
      } else {
        a = window.sessionStorage.getItem('stationIds')
      };
      const { data: res } = await this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }

      })
      this.stationList = res.data;


      // if (res.code !== 0) return this.$message.error(res.msg)
      // res.data.forEach(item => {
      //   const obj = {
      //     label: '',
      //     value: ''
      //   }
      //   obj.label = item.name
      //   obj.value = item.id
      //   this.powerStationOptions.push(obj)
      // })
      if (row.id === undefined || row.id === null) {
        this.title = '添加直充用户'
      } else {
        this.title = '编辑直充用户'
        const { data: res } = await this.$http.get('charge-vin/getChargeVinById/' + row.id)
        if (res.code !== 0) {
          row.b_j_anniu = false;
          this.$message.error(res.msg)
        } else {
          row.b_j_anniu = true;
          this.vinAccountForm = res.data
        }

      }
    },
    // 添加&编辑直充用户事件
    editVinAccount() {
      this.$refs.vinAccountFormRef.validate(async valid => {
        if (!valid) return
        this.vinAccountForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (this.title === '添加直充用户') {
          const { data: res } = await this.$http.post('charge-vin/addChargeVin', this.vinAccountForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('添加直充用户成功！')
          this.getVinAccountList()
          this.editDialogVisible = false
        } else {
          const { data: res } = await this.$http.post('charge-vin/updateChargeVinById', this.vinAccountForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('编辑直充用户成功！')
          this.getVinAccountList()
          this.editDialogVisible = false
        }
      })
    },
    // 添加&编辑直充用户对话框关闭事件
    editDialogClosed() {
      this.$refs.vinAccountFormRef.resetFields();
      this.editDialogVisible = false;
      this.vinAccountList.forEach(element => {
        element.b_j_anniu = false;
      });
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 删除vin用户方法
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该VIN用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('vin/delVinById/' + this.ids)
        if (res.code !== 0) {
          return this.$message.error('删除VIN用户失败！')
        }
        this.$message.success('删除VIN用户成功！')
        this.getVinAccountList()
      } else {
        const { data: res } = await this.$http.post('vin/delVinById/' + row.id)
        if (res.code !== 0) {
          return this.$message.error('删除VIN用户失败！')
        }
        this.$message.success('删除VIN用户成功！')
        this.getVinAccountList()
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
