<template>
    <div></div>
</template>
<script>
export default {
  name: 'blankWorld',
  beforeRouteEnter (to, from, next) {
    if (from.path == '/role') {
      next(vm => {
        vm.$router.replace(from.path)
      })
    } else if (from.path == '/tariff') {
      next(vm => {
        vm.$router.replace(from.path)
      })
    } else if (from.path == '/sysParam') {
      next(vm => {
        vm.$router.replace(from.path)
      })
    } else if (from.path == '/powerStation') {
      next(vm => {
        vm.$router.replace(from.path)
      })
    }
  }
}
</script>
