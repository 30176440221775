<template>
  <div>
    <!-- 卡片视图区域 微信用户-->
    <el-card>
      <!--  -->
      <el-row>
        <span>用户名：</span>
        <el-input style="width: 300px;margin-right: 40px;" placeholder="请输入用户名" clearable v-model="queryInfo.userNumber"
          @clear="getWechartList" @change="current"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="getWechartList">查询</el-button>
        <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
      </el-row>
      <el-table :data="wechartList" border empty-text="暂无数据">
        <el-table-column align="center" label="唯一编码" prop="userNumber"></el-table-column>
        <el-table-column align="center" label="变动前金额（元）" width="300">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.startMoney" style="margin-right: 10px;" @change="gaibian1(scope.row)"
              :clearable="true" :min="0" :precision="2" placeholder="请输入变动前金额">
            </el-input-number>
            <span>元</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="变动金额（元）" width="300">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.changMoney" style="margin-right: 10px;" @change="gaibian2(scope.row)"
              :clearable="true" :min="0" :precision="2" placeholder="请输入变动金额">
            </el-input-number>
            <span>元</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="新余额（元）" width="300">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.newMoney" style="margin-right: 10px;" @change="gaibian3(scope.row)"
              :clearable="true" :min="0" :precision="2" placeholder="请输入新金额">
            </el-input-number>
            <span>元</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="余额（元）" width="300">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.lastMoney" style="margin-right: 10px;" @change="gaibian4(scope.row)"
              :clearable="true" :min="0" :precision="2" placeholder="请输入金额">
            </el-input-number>
            <span>元</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单编号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="退款状态" prop="isRefund">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isRefund == 0 ? 'error' : 'success'">
              {{ scope.row.isRefund == 0 ? '未退' : '已退' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作类型" prop="usingType">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.usingType == '0' ? 'warning' : scope.row.usingType == '1' ? 'danger' : scope.row.usingType == '2' ? 'success' : scope.row.usingType == '3' ? 'default' : 'warning'">
              {{ scope.row.usingType == '0' ? '消费' : scope.row.usingType == '1' ? '充值' : scope.row.usingType ==
          '2' ? '退款' : scope.row.usingType == '3' ? '赠送' : '欠费' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="des"></el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination v-if="wechartList.length > 0" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'wechartAccountWorld',
  data() {
    return {
      num: 0,
      queryInfo: {
        payId: '',
        billCode: '',
        userNumber: '',
        pileCode: '',
        consumeType: '',
        usingType: '',
        page: 1,
        limit: 10
      },
      total: null,
      // 微信用户列表
      wechartList: [],
      obj: {
        id: 0,
        changMoney: 0,
        lastMoney: 0,
        newMoney: 0,
        startMoney: 0
      }
    }
  },
  computed: {
    jisuan() {
      return function (val) {
        const hr = val / 100
        return hr
      }
    },
  },
  methods: {
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        payId: '',
        billCode: '',
        userNumber: '',
        pileCode: '',
        consumeType: '',
        usingType: '',
        page: 1,
        limit: 1000
      };
      this.getWechartList();
    },
    async gaibian1(row) {
      this.obj.id = row.id;
      this.obj.changMoney = row.changMoney;
      this.obj.lastMoney = row.lastMoney;
      this.obj.newMoney = row.newMoney;
      this.obj.startMoney = row.startMoney * 100;
      const { data: res } = await this.$http.post('consume-log/updConsumeLog', this.obj)
      if (res.data.code == 0) {
        this.$message.success('修改成功');
        this.getWechartList()
      } else {
        this.$message.error('修改失败')
      }
    },
    async gaibian2(row) {
      this.obj.id = row.id;
      this.obj.changMoney = row.changMoney * 100;
      this.obj.lastMoney = row.lastMoney;
      this.obj.newMoney = row.newMoney;
      this.obj.startMoney = row.startMoney;
      const { data: res } = await this.$http.post('consume-log/updConsumeLog', this.obj)
      if (res.code == 0) {
        this.$message.success('修改成功');
        this.getWechartList()
      } else {
        this.$message.error('修改失败')
      }
    },
    async gaibian3(row) {
      this.obj.id = row.id;
      this.obj.changMoney = row.changMoney;
      this.obj.lastMoney = row.lastMoney;
      this.obj.newMoney = row.newMoney * 100;
      this.obj.startMoney = row.startMoney;
      const { data: res } = await this.$http.post('consume-log/updConsumeLog', this.obj)
      if (res.code == 0) {
        this.$message.success('修改成功');
        this.getWechartList()
      } else {
        this.$message.error('修改失败')
      }
    },
    async gaibian4(row) {
      this.obj.id = row.id;
      this.obj.changMoney = row.changMoney;
      this.obj.lastMoney = row.lastMoney * 100;
      this.obj.newMoney = row.newMoney;
      this.obj.startMoney = row.startMoney;
      const { data: res } = await this.$http.post('consume-log/updConsumeLog', this.obj)
      if (res.code == 0) {
        this.$message.success('修改成功');
        this.getWechartList()
      } else {
        this.$message.error('修改失败')
      }
    },
    async xiugai(row) {
      this.obj.id = row.id;
      this.obj.changMoney = row.changMoney;
      this.obj.lastMoney = row.lastMoney;
      this.obj.newMoney = row.newMoney;
      this.obj.startMoney = row.startMoney;
      const { data: res } = await this.$http.get('consume-log/updConsumeLog', { params: this.obj })
      if (res.code == 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 根据分页获取对应的微信用户列表
    async getWechartList() {
      this.wechartList = [];
      const { data: res } = await this.$http.get('consume-log/getAllConsumeLog', { params: this.queryInfo })
      if (res.code == 0) {
        this.wechartList = res.data;
        this.wechartList.forEach(item => {
          item.money = (item.money / 100).toFixed(2);
          item.changMoney = (item.changMoney / 100).toFixed(2);
          item.startMoney = (item.startMoney / 100).toFixed(2);
          item.lastMoney = (item.lastMoney / 100).toFixed(2);
          item.newMoney = (item.newMoney / 100).toFixed(2);
          if (item.status == 1) {
            item.status = true
          } else {
            item.status = false
          }
        })
        this.total = res.count
      }
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getWechartList()
    }
  }
}
</script>

<style lang="less" scoped></style>
