import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    history: [],
    historyPath: [],
    currentPath: null,
    all: []
  },
  getters: {
  },
  mutations: {
    moren(state, payload) {
      state.all = payload
    },
    // 清空历史标签(关闭所有)
    qingkong(state, payload) {
      state.all = payload;
      window.sessionStorage.setItem('tablist', JSON.stringify(state.all));
    },
    // 路由添加
    luyou(state, payload) {
      if(state.all==null)return state.all=[];
      state.all.push(payload);
      state.all = [...new Set(state.all.map(JSON.stringify))].map(JSON.parse);
      window.sessionStorage.setItem('tablist', JSON.stringify(state.all));
    },
    // 添加历史路由
    addHistoryPath(state, path) {
      let paths = state.historyPath.filter(item => {
        return item != url;
      })
      paths.push(url);
      state.historyPath = paths;
      state.currentPath = url;
    },
    // 添加历史路由路径
    addHistory(state, payload) {
      let index = -1;
      state.history.forEach((item, i) => {
        if (item.url == history.url)
          index = i;
      })
      if (index !== -1) {
        return;
      }
      state.history.push({
        menunae: payload.menunae,
        url: payload.url
      })
    },
    // 关闭当前
    del(state, payload) {
     if(state.all.length<2)return
      state.all.forEach((item, index) => {
        if (item.url == payload) {
          state.all.splice(index, 1)
        }
      });
      window.sessionStorage.setItem('tablist', JSON.stringify(state.all));
    },
    // 关闭其他
    del_event(state, payload) {
      let abc = [];
      state.all.forEach((item, index) => {
        if (item.url == payload) {
          abc.push(item)
        }
      });
      state.all = abc;
      window.sessionStorage.setItem('tablist', JSON.stringify(state.all));
    },
    // 删除历史路由
    removeHistory(state, payload) {
      console.log(state.history, payload, 'store,history,78');
      state.history = state.history.filter(item => {
        return item.url != payload.url;
      })
    },
    // 删除历史路由路径
    removeHistoryPath(state, payload) {
      console.log(state.history, payload, 'store,history,85');
      state.historyPath = state.historyPath.filter(item => {
        return item !== payload.url;
      })
      if (state.historyPath.length > 0) {
        state.currentPath = state.historyPath[state.historyPath.length - 1]
      }
      else {
        state.currentPath = null;
      }
    }
  },
  actions: {
  },
  getters: {
    history: state => state.history.history,
    currentPath: state => state.history.currentPath
  },
  modules: {
  }
})
