<template>
  <!-- 卡片视图区域 -->
  <el-card class="card">
    <el-form :inline="true" :model="queryInfo" class="form">
      <div>
        <el-form-item label="所属电站">
          <el-select v-model="suoshudianzhan" placeholder="请选择" @change="change_value" clearable
            @clear="getPowerStationList" filterable>
            <el-option v-for="item in powerStationList" :key="item.id" :label="item.stationName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户编号">
          <el-input placeholder="请输入用户编号" clearable @keyup.enter.native="getPowerStationList"
            v-model="queryInfo.userNumber" @change="current" @clear="getPowerStationList">
          </el-input>
        </el-form-item>
        <el-form-item label="用户手机号">
          <el-input placeholder="请输入用户手机号" clearable @change="current" @keyup.enter.native="getPowerStationList"
            v-model="queryInfo.phone" @clear="getPowerStationList">
          </el-input>
        </el-form-item>
        <el-form-item label="停车时间">
          <el-date-picker type="datetimerange" :picker-options="pickerOptions" v-model="values" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" @change="changeTime">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否减免停车费用">
          <el-select v-model="queryInfo.isDeduction" placeholder="请选择" clearable @clear="getPowerStationList"
            filterable>
            <el-option v-for="item in isDeductionList" :key="item.isDeduction" :label="item.stationName"
              :value="item.isDeduction">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="queryInfo.payState" @change="current" placeholder="请选择" clearable
            @clear="getPowerStationList" filterable>
            <el-option v-for="items in payStateList" :key="items.payState" :label="items.stationName"
              :value="items.payState">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="debounceClick">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-form-item>
      </div>
      <!-- <el-form-item label="启动方式" :span="3">
              <el-select v-model="queryInfo.startType" placeholder="请选择" clearable @clear="getPowerStationList"
                style="width: 150px;" filterable>
                <el-option v-for="item in startTypeList" :key="item.startType" :label="item.stateName"
                  :value="item.startType">
                </el-option>
              </el-select>
            </el-form-item> -->
    </el-form>
    <!-- table表格区域 -->
    <el-table class="table" :data="chargingList" border empty-text="暂无数据">
      <el-table-column align="center" label="序列号" prop="id" width="80"></el-table-column>
      <el-table-column align="center" label="停车订单编号" prop="parkBillCode"></el-table-column>
      <el-table-column align="center" prop="groundLockCode" label="地锁编号" width="200"></el-table-column>
      <el-table-column align="center" label="用户唯一编码规则" prop="userNumber" width="140"></el-table-column>
      <el-table-column align="center" label="用户手机号" prop="phone" width="110"></el-table-column>
      <el-table-column align="center" label="支付类型" prop="startType" width="120">
        <template slot-scope="scope">
          <!-- {{scope.row.startType}} -->
          <img style="width: 40px;
           height: 40px;" :src="scope.row.startType == 0 ? tupian + '小程序.png' : tupian + 'app.png'" alt="">
        </template>
      </el-table-column>
      <el-table-column align="center" label="停车时间" prop="parkTime" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.parkTime == null">--</span>
          <span v-else>{{ scope.row.parkTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="驶离时间" prop="departureTime" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.departureTime == null">--</span>
          <span v-else>{{ scope.row.departureTime }}</span>
        </template>
      </el-table-column>
      <!-- <template scope="scope">
            <span v-if="scope.row.parkingFees==''">--</span>
            <span v-else>{{ scope.row.parkingFees }}元</span>
          </template> -->

      <el-table-column align="center" label="停车费用" width="80">
        <template scope="scope">
          <span>{{ timesalls(scope.row.parkingFees, 100, 2) }}元</span><el-tag
            :type="scope.row.isDeduction == 0 ? 'warning' : ''">{{ scope.row.isDeduction == 0 ? '减免' : '不减免' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="减免单号" prop="deductionNumber">
        <template scope="scope">
          <span>{{ scope.row.isDeduction == 1 ? '--' : scope.row.deductionNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="支付状态" prop="payState" width="80">
        <template scope="scope">
          <el-tag :type="scope.row.payState == 0 ? 'error' : scope.row.payState == 1 ? 'success' : 'warning'">{{
      scope.row.payState == 0 ? '未支付' : scope.row.payState == 1 ? '已支付' : '停车中' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="createTime" width="100">
        <template scope="scope">
          <span v-if="scope.row.createTime == null">--</span>
          <span v-else>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新时间" prop="updateTime" width="100">
        <template scope="scope">
          <span v-if="scope.row.updateTime == null">--</span>
          <span v-else>{{ scope.row.updateTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页导航区域 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total" background>
    </el-pagination>
  </el-card>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'chargingWorld',
  data() {
    return {
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.values = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      tupian: this.$imgUrl,
      pickerMinDate: '',
      values: [],
      isDeductionList: [
        {
          isDeduction: 0,
          stationName: '减免'
        }, {
          isDeduction: 1,
          stationName: '不减免'
        }
      ],
      payStateList: [
        {
          payState: 0,
          stationName: '未支付'
        }, {
          payState: 1,
          stationName: '已支付'
        }, {
          payState: 2,
          stationName: '停车中'
        }
      ],
      yincang: true,
      page_num: 0,
      pileType_list: [
        {
          pileType: 0,
          label: '锐创直流桩'
        },
        {
          pileType: 1,
          label: '锐创交流桩'
        },
        {
          pileType: 2,
          label: '无疆(二代)交流桩'
        },
        {
          pileType: 3,
          label: '光法(一代)直流桩'
        },
        {
          pileType: 4,
          label: '光法(一代)交流桩'
        },
        {
          pileType: 5,
          label: '锐创低速桩'
        },
        {
          pileType: 6,
          label: '其他电桩'
        }
      ],
      startTypeList: [
        {
          startType: 0,
          stateName: '小程序启动'
        },
        {
          startType: 1,
          stateName: '平台卡启动'
        },
        {
          startType: 2,
          stateName: '独立卡启动'
        },
        {
          startType: 3,
          stateName: '超级卡启动'
        },
        {
          startType: 4,
          stateName: 'Vin启动'
        },
        {
          startType: 5,
          stateName: '互联互通启动'
        },
        {
          startType: 9,
          stateName: '位置启动方式'
        },
      ],
      // tupian: tupian,
      caozuoquanxian: false,
      shifou: null,
      // 查询充电订单列表所需参数
      tupian: this.$imgUrl,
      queryInfo: {
        userNumber: '',
        payState: '',
        deductionNumber: '',
        departureTime: '',
        parkTime: '',
        parkingFees: '',
        isDeduction: '',
        page: 1,
        limit: 10,
        phone: '',
        billInfoType: 1,
        adminId: window.sessionStorage.getItem('adminId'),
        stationIds: window.sessionStorage.getItem('stationIds')
      },
      // 充电订单数据
      chargingList: [],
      stateName: '',
      total: 0,
      value: '',
      suoshudianzhan: null,
      detailList: [],
      // 电站列表数据
      powerStationList: [],
      // 是否已支付
      isPay: [
        {
          label: "未支付",
          payState: 0
        }, {
          label: "已支付",
          payState: 1
        }, {
          label: "停车中",
          payState: 2
        },
      ],
      // 是否无效订单数据
      invalidOrder: [
        {
          label: "是",
          defaultValue: 0
        },
        {
          label: "否",
          defaultValue: 1
        }
      ]
    }
  },
  created() {
    caozuo(106).then(res => {
      this.caozuoquanxian = res;
    })
    this.getPowerStationList()
  },
  watch: {
    chargingList: {
      handler(newval, oldval) {
        this.chargingList = newval
      }
    }
  },
  computed: {
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    timesalls() {
      return function (value, num, count) {
        return (value / num).toFixed(count)
      }
    },
  },
  methods: {
    change_value(e) {
      this.queryInfo.page = 1;
      if (e !== null && e !== undefined && e !== '') return this.queryInfo.stationIds = e;
    },
    current() {
      this.queryInfo.page = 1;
    },
    changeTime(value) {
      this.queryInfo.page = 1;
      if (value) {
        this.queryInfo.parkTime = value[0];
        this.queryInfo.departureTime = value[1];
        if (this.queryInfo.parkTime == this.queryInfo.departureTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.parkTime = ''
          this.queryInfo.departureTime = ''
        }
      } else {
        this.queryInfo.parkTime = null
        this.queryInfo.departureTime = null
        this.pickerMinDate = '';
        this.getChargingOrderList()
      }
    },
    // 查询所有搜索条件
    debounceClick() {
      this.getChargingOrderList()
    },
    zhanshi() {
      this.yincang = !this.yincang
    },
    // 重置搜索条件
    chongzhi() {
      this.queryInfo = {
        userNumber: '',
        payState: '',
        deductionNumber: '',
        departureTime: '',
        parkTime: '',
        parkingFees: '',
        isDeduction: '',
        page: 1,
        limit: 10,
        phone: '',
        billInfoType: 1,
        adminId: window.sessionStorage.getItem('adminId')
      };
      this.suoshudianzhan = '';
      this.getChargingOrderList()
    },
    // 搜索充电订单列表
    async getChargingOrderList() {
      const that = this;
      that.chargingList = []; 
      that.total =0;
      that.queryInfo.stationIds = that.queryInfo.stationIds == null ? window.sessionStorage.getItem('stationIds') : that.suoshudianzhan;
      await that.$http.get('bill-park-info/getAllBillParkInfo', { params: that.queryInfo }).then(resb => {
        if (resb.data.code !== 0) return;
        that.chargingList = resb.data.data;
        that.total = resb.data.count;
      });
    },
    // 监听pageSize改变的事件
    handleSizeChange(pageSize) {
      this.queryInfo.limit = pageSize;
      this.getPowerStationList()
    },
    // 获取电站列表数据
    getPowerStationList() {
      this.chargingList = [];
      this.powerStationList = [];
      this.queryInfo.stationIds = this.suoshudianzhan ? this.suoshudianzhan : window.sessionStorage.getItem('stationIds');
      if (this.queryInfo.stationIds == null || this.queryInfo.stationIds == undefined) return;
      this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(resb => {
        if (resb.data.code == 0) {
          resb.data.data.forEach(item => {
            this.powerStationList.push({
              id: item.id,
              stationName: item.stationName
            })
          })
        }
      });
      this.$http.get('bill-park-info/getAllBillParkInfo', { params: this.queryInfo }).then(resb => {
        if (resb.data.code == 0) {
          this.chargingList = resb.data.data;
          this.total = resb.data.count;
        }
      });
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getPowerStationList()
    },
    // 展示订单包含子项内容
    async showDetail(id) {
      const { data: res } = await this.$http.get('order/getOrderById?id=' + id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.detailList = res.data
    },
    // 双击展开折叠
    dbclick(row, column, event) {
      // console.log(event.currentTarget.querySelector(".el-table__expand-icon"))
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click()
      }
    },
    // 结算订单
    async jiesuan(row) {
      const confirmResult = await this.$confirm('此操作将结算该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消结算！')
      }
      const { data: res } = await this.$http.post('bill-info/billSettlement', {
        id: row.id,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        startType: row.startType,
        billMoney: row.billMoney,
        billInfoType: 1
      })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('结算成功！')
      this.getPowerStationList()
    }
  }
}
</script>

<style></style>