<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row :gutter="20">
        <el-col>
          <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
            <el-form-item label="操作模块：">
              <el-input v-model="queryInfo.operateModule" clearable @clear="getLogList" @change="current"></el-input>
            </el-form-item>
            <el-form-item label="操作类型：">
              <el-input v-model="queryInfo.operateType" clearable @clear="getLogList" @change="current"></el-input>
            </el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getLogList">查询</el-button>
            <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
          </el-form>
        </el-col>

        <!-- <el-col :span="1" style="margin-left: 30px;">
          <el-button @click="removeById">批量删除</el-button>
        </el-col> -->
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="logList" border stripe @selection-change="handleSelectionChange" empty-text="暂无数据">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="ID序列" prop="id" width="100px"></el-table-column>
        <el-table-column align="center" label="操作员" prop="adminName" width="100px"></el-table-column>
        <el-table-column align="center" label="操作模块" prop="operateModule" width="100px"></el-table-column>
        <el-table-column align="center" label="操作类型" prop="operateType" width="100px"></el-table-column>
        <el-table-column align="center" label="原数据(json)" prop="rawData"
          :show-overflow-tooltip='true'></el-table-column>
        <el-table-column align="center" label="新数据(json)" prop="newData"
          :show-overflow-tooltip='true'></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="100">
          <template slot-scope="scope">
            {{ scope.row.createTime | dateFormat }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" width="130px">
          <template slot-scope="scope">
            <el-button size="small" type="danger" @click="removeById(scope.row)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'logWorld',
  data() {
    return {
      // 查询参数对象
      queryInfo: {
        startTime: null,
        adminId: window.sessionStorage.getItem('adminId'),
        endTime: null,
        operateType: null,
        operateModule: null,
        page: 1,
        limit: 8
      },
      // 商品列表
      logList: [],
      chooseLogList: [],
      // 总数据条数
      total: 0,
      value: '',
      ids: ''
    }
  },
  created() {
    this.getLogList()
  },
  methods: {
    current(){
      this.queryInfo.page=1;
    },
    chongzhi() {
      this.queryInfo = {
        startTime: null,
        // adminId: window.sessionStorage.getItem('adminId'),
        endTime: null,
        operateType: null,
        operateModule: null,
        // title: '',
        page: 1,
        limit: 8
      };
      this.getLogList();
    },
    changeTime(value) {
      if (value) {
        this.queryInfo.startTime = value[0]
        this.queryInfo.endTime = value[1]
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        console.log(value)
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.getLogList()
      }
    },
    // 根据分页获取对应的日志列表
    async getLogList() {
      const { data: res } = await this.$http.get('operationLog/getAllAdminOperationLog', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.logList = res.data
      this.total = res.count
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getLogList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getLogList()
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 删除日志
    // async removeById(row) {
    //   const confirmResult = await this.$confirm('此操作将永久删除该日志, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).catch(err => err)
    //   if (confirmResult !== 'confirm') {
    //     return this.$message.info('已经取消删除！')
    //   }
    //   if (row.id === null || row.id === '' || row.id === undefined) {
    //     const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + this.ids)
    //     if (res.code !== 0) {
    //       return this.$message.error('删除日志失败！')
    //     }
    //     this.$message.success('删除日志成功！')
    //     this.getLogList()
    //   } else {
    //     const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + row.id.toString())
    //     if (res.code !== 0) {
    //       return this.$message.error('删除日志失败！')
    //     }
    //     this.$message.success('删除日志成功！')
    //     this.getLogList()
    //   }
    // }
  }
}
</script>

<style lang="less" scoped></style>
