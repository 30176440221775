import $http from "axios";
export default function caozuo(ids) {
    return new Promise((resolve, reject) => {
        $http.get(`/adminRoleMenu/getAllMenuByRoleId/${window.sessionStorage.getItem('roleId')}`).then(res => {
            if (res.data.code != 0) {
                return reject('您没有此权限！')
            } else {
                if(res.data.data.menuId==null)return
                if(res.data.data.menuId.length<1)return
                if(!res.data.data.menuId.includes(','))return
                const hr = res.data.data.menuId.split(",");
                const b = hr.filter(item => {
                    return item == ids
                });
                if (b.length > 0) {
                    return resolve(true)
                } else {
                    return resolve(false)
                }
            }
        })
    })
}
