<template>
    <div class="home">
      <div class="he_right" style="display: flex;flex-direction: row;">
        <el-select v-model="sousuodianzhan" placeholder="请选择电站" @change="change_vale" filterable>
          <el-option v-for="item in powerStationList" :key="item.id" :label="item.stationName" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="top">
        <h4>
          <div class="he_left" style="margin-top: -10px;">
            今日收入（元）
          </div>
        </h4>
        <div class="dl" style="margin-top: -20px;padding-bottom: 10px;">
          <li v-for="(item, index) in money_day_list" :key="index">
            <p>{{ item.h4 }}</p>
            <p>{{ chuli(item.price) }}</p>
          </li>
        </div>
      </div>
      <div class="center">
        <div class="right">
          <!-- 饼图*3 -->
          <el-col :span="8" v-for="(item, index) in list" :key="index">
            <div class="cols"></div>
          </el-col>
          <!-- bingtu -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts'
  export default {
    name: 'welcomeWorld',
    data() {
      return {
        sousuodianzhan: '全部电站',
        zhuangTypelist: [],
        dandu: {
          startTime: null,
          endTime: null,
        },
        Wxmoney1: 0,
        Wxmoney2: 0,
        Wxmoney3: 0,
        Alipaymoney1: 0,
        Alipaymoney2: 0,
        Alipaymoney3: 0,
        alipayTotalRefundMoney: 0,
        alipayTotalGiveMoney: 0,
        alipayTotalRechargeMoney: 0,
        queryInfo: {
          nickName: '',
          userName: '',
          userNumber: '',
          stationIds: window.sessionStorage.getItem('stationIds'),
          phone: '',
          adminId: window.sessionStorage.getItem('adminId'),
          roleId: window.sessionStorage.getItem('roleId'),
          page: 1,
          limit: 10
        },
        totalRechargeMoney: 0,
        totalRefundMoney: 0,
        totalGiveMoney: 0,
        arr_time: [],
        totalMoneys: 0,
        dianfei: null,
        arr1: [],
        arr2: [],
        arr3: [],
        arr4: [],
        operateTime: [],
        value1: '',
        picker_value2: ['', ''],
        value3: '',
        list: [{
          id: 1,
          value1: 0,
          value2: 0,
          name: "很好",
          color: '#9DE490'
        },
        {
          id: 2,
          value1: 0,
          value2: 0,
          name: "离线",
          color: '#6FA1FF'
        },
        {
          id: 2,
          value1: 0,
          value2: 0,
          name: "故障",
          color: '#6FA1FF'
        },
        {
          id: 3,
          value1: 0,
          value2: 0,
          name: "其他",
          color: '#657798'
        }
        ],
        Max_num: 0,
        money_all: '61096.88',
        money_day_list: [
          {
            h4: '总台数（台）',
            price: ''
          },
          {
            h4: '锐创直流',
            price: ''
          },
          {
            h4: '锐创交流',
            price: ''
          },
          {
            h4: '光法直流',
            price: ''
          },
          {
            h4: '无疆交流',
            price: ''
          },
          {
            h4: '低速',
            price: ''
          }
        ],
        powerStationList: [],
        dianliang: null,
        pars: {
          stationIds: window.sessionStorage.getItem('stationIds'),
          startTime: null,
          endTime: null,
          todayTime: null,
          roleId: window.sessionStorage.getItem('roleId'),
        },
        all: null,
        arr: []
      }
    },
    created() {
      this.default();
      this.bingtu();
      this.zongshouru()
    },
    computed: {
      chuli: function () {
        return function (val) {
          return (val / 100).toFixed(2)
        }
      }
    },
    methods: {
      pickBlur() {
        this.pickerMinDate = ''
      },
      // 所属电站搜索条件
      async change_vale(e) {
        this.pars.stationIds = String(e);
        this.shuju();
        this.bingtu();
        this.moren();
      },
      //常用电站
      chage_zhuangType(e) {
        this.queryInfo.stationIds = e;
      },
      // 初始
      async default() {
        this.powerStationList.push({
          id: window.sessionStorage.getItem('stationIds'),
          stationName: '全部电站'
        });
        await this.$http.get(`bill-info/getAllRechargeRefund/${window.sessionStorage.getItem('adminId')}`).then(ress => {
          if (ress.data.code !== 0) return;
          this.totalRechargeMoney = ress.data.data.totalRechargeMoney;
          this.totalRefundMoney = ress.data.data.totalRefundMoney;
          this.totalGiveMoney = ress.data.data.totalGiveMoney;
        });
        await this.$http.get('station-info/getAllStationInfoName', {
          params: {
            roleId: window.sessionStorage.getItem('roleId'),
            stationIds: window.sessionStorage.getItem('stationIds'),
            stationMode: '0,1,2'
          }
        }).then(res => {
          res.data.data.forEach(item => {
            this.powerStationList.push({
              id: item.id,
              stationName: item.stationName
            })
          });
        });
        await this.$http.get(`bill-info/getAllRechargeRefund/${Number(window.sessionStorage.getItem('adminId'))}`).then(resb => {
          if (resb.data.code !== 0) return;
          if (resb.data.data == null) return;
          this.Wxmoney1 = resb.data.data.totalRechargeMoney;
          this.Wxmoney2 = resb.data.data.totalRefundMoney;
          this.Wxmoney3 = resb.data.data.totalGiveMoney;
          this.Alipaymoney1 = resb.data.data.alipayTotalRechargeMoney;
          this.Alipaymoney2 = resb.data.data.alipayTotalRefundMoney;
          this.Alipaymoney3 = resb.data.data.alipayTotalGiveMoney;
        });
        this.moren()
      },
      // 默认
      async moren() {
        const { data: res } = await this.$http.get('bill-info/getTodayBill', {
          params: this.pars
        })
        this.money_day_list[0].price = res.data[0].totalBillMoney * 1;
        this.money_day_list[1].price = res.data[0].totalChargeMoney * 1;
        this.money_day_list[2].price = res.data[0].totalServiceMoney * 1;
        this.money_day_list[3].price = res.data[0].totalKwhs * 1;
      },
      // 时间选择器
      picker_event(e) {
        if (e != null) {
          this.pars.startTime = e[0];
          this.pars.endTime = e[1];
          this.dandu.startTime = e[0];
          this.dandu.endTime = e[1];
        } else {
          this.pars.startTime = null;
          this.pars.endTime = null;
          this.dandu.startTime = null;
          this.dandu.endTime = null;
          this.pickerMinDate = ''
        }
        this.bingtu();
        this.zongshouru();
      },
      // 总收入
      zongshouru() {
        this.shuju()
      },
      // 数据
      async shuju() {
        const { data: resb } = await this.$http.get('station-info/getAllStationInfoCount', {
          params: this.pars
        })
        if(resb.code!==0)return;
        console.log(resb,'252');
        // this.money_mounth_list[0].price_all = resb.data[0].totalBillMoney * 1;
        // this.money_mounth_list[0].price_mounth = resb.data[0].lastMonthTotalBillMoney * 1;
        // this.money_mounth_list[1].price_all = resb.data[0].totalChargeMoney;
        // this.money_mounth_list[1].price_mounth = resb.data[0].lastMonthTotalChargeMoney * 1;
        // this.money_mounth_list[2].price_all = resb.data[0].totalServiceMoney;
        // this.money_mounth_list[2].price_mounth = resb.data[0].lastMonthTotalServiceMoney * 1;
        // this.money_mounth_list[3].price_all = resb.data[0].totalKwhs * 1;
        // this.money_mounth_list[3].price_mounth = resb.data[0].lastMonthTotalKwhs * 1;
      },
      // 饼图
      async bingtu() {
        const { data: resb } = await this.$http.get('bill-info/getAllBill', {
          params: this.pars
        })
        let abc = resb.data[0].totalMoneys;
        if (resb.data[0].totalAppMoney == '0' || resb.data[0].totalAppMoney == null) {
          this.list[0].value1 = 0;
        } else {
          this.list[0].value1 = (resb.data[0].totalAppMoney / 100).toFixed(2);
        }
        if (resb.data[0].totalICMoney == '0' || resb.data[0].totalICMoney == null) {
          this.list[1].value1 = 0;
        } else {
          this.list[1].value1 = (resb.data[0].totalICMoney / 100).toFixed(2);
        }
        if (resb.data[0].totalOtherMoney == '0' || resb.data[0].totalOtherMoney == null) {
          this.list[2].value1 = 0;
        } else {
          this.list[2].value1 = (resb.data[0].totalOtherMoney / 100).toFixed(2);
        }
        if (resb.data[0].totalMoneys == '0' || resb.data[0].totalMoneys == null) {
          this.totalMoneys = 0;
          this.list[0].value2 = this.list[1].value2 = this.list[2].value2 = 0;
        } else {
          this.totalMoneys = (abc / 100).toFixed(2);
          this.list[0].value2 = this.list[1].value2 = this.list[2].value2 = this.totalMoneys;
        }
        var echarts = require("echarts");
        var roseCharts = document.getElementsByClassName("cols"); // 对应地使用ByClassName
        for (var i = 0; i < roseCharts.length; i++) {
          // 通过for循环，在相同class的dom内绘制元素
          var myChart = echarts.init(roseCharts[i]);
          myChart.setOption({
            legend: {
              top: '5%',
              left: 'left',
              selectedMode: false
            },
            series: [
              {
                type: "pie",
                color: this.list[i].color,
                radius: ['70%', '40%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: true,
                startAngle: 360,
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 20,
                  }
                },
                labelLine: {
                  show: false
                },
                data: [
                  {
                    value: this.list[i].value1 * 2,
                    value2: this.totalMoneys * 2,
                    name: this.list[i].name,
                    label: {
                      show: true,
                      position: 'left',
                      formatter(param) {
                        if (param.data.value2 == 0) {
                          return param.data.value + '元' + '占比' + param.data.value2 + '%';
                        } else {
                          return (param.data.value / 2) + '元' + '占比' + ((param.data.value / param.data.value2) * 100).toFixed(0) + '%';
                        }
                      }
                    }
                  },
                  {
                    value: this.totalMoneys,
                    itemStyle: {
                      color: '#efefef',
                      decal: {
                        symbol: 'none'
                      }
                    }
                  }
                ],
              },
            ],
          });
        }
      },
      formatDate(datetime, type) {
        const time = new Date(datetime)
        const year = time.getFullYear()
        const month = (time.getMonth() + 1).toString().padStart(2, '0')
        const date = (time.getDate()).toString().padStart(2, '0')
        return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
      },
      getDate() {
        const start = new Date()
        this.picker_value2[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
        this.picker_value2[1] = this.formatDate(start.getTime(), 'end')
      },
    }
  }
  
  </script>
  
  <style lang="less" scoped>
  .home {
    display: flex;
    flex-direction: column;
  
    .he_right {
      margin-bottom: 10px;
    }
  
    .top {
      display: flex;
      flex: 1;
      flex-direction: column;
  
      h4 {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 18px;
      }
  
      .dl {
        flex: 9;
        display: flex;
        flex-direction: row;
        font-size: 18px;
        overflow: auto;
  
        li {
          flex: 1;
          display: flex;
          flex-direction: column;
          border-right: 1px solid gray;
          padding-left: 20px;
  
          p:nth-child(2) {
            font-weight: bolder;
          }
        }
  
        li:first-child {
          padding-left: 0;
        }
        
        // ,li:nth-child(5)
        li:last-child {
          dd {
            display: flex;
            flex-direction: column;
            /* font-size: 18px; */
            margin-left: -1px;
            font-weight: bolder;
  
            div:nth-child(2)>span:nth-child(2) {
              padding-left: 2px;
            }
          }
        }
  
        li:last-child {
          border: none;
        }
      }
    }
  
    .center {
      flex: 2;
      display: flex;
      flex-direction: row;
      font-weight: bolder;
  
      .left {
        margin-top: 20px;
        margin-bottom: 20px;
        flex: 2;
        font-size: 18px;
        border-right: 1px solid gray;
      }
  
      .right {
        flex: 8;
        display: flex;
        flex-direction: row;
        align-items: center;
  
  
        .el-col {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
  
          .cols {
            flex: 1;
          }
        }
      }
    }
  
    .bottom {
      flex: 2;
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
  
      h4 {
        margin-top: 10px;
        flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: -5px;
  
        .left {
          flex: 8;
          font-weight: bolder;
          font-size: 18px;
        }
  
        .right {
          flex: 2;
          display: flex;
          justify-content: flex-end;
          overflow: hidden;
  
          .el-date-editor {
            width: 100%;
          }
        }
      }
  
      .dl_all {
        flex: 9;
        display: flex;
        flex-direction: row;
  
        dl {
          flex: 1;
          display: flex;
          border: 1px solid gray;
          border-radius: 15px;
          flex-direction: column;
          padding-left: 20px;
          margin-right: 10px;
  
          dt {
            flex: 8;
            font-size: 18px;
  
            p:nth-child(2) {
              font-weight: bolder;
            }
          }
  
          dd {
            margin-top: -10px;
            flex: 2;
            margin-left: -1px;
            color: blue;
            font-size: 18px;
  
            span:last-child {
              font-weight: bolder;
            }
          }
        }
  
        dl:last-child {
          margin-right: 0px;
          padding-right: 10px;
  
          dd {
            font-size: 18px;
          }
        }
      }
    }
  
    .top,
    .center,
    .bottom,
    .tiaojian {
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      background: #fff;
      border-radius: 15px;
      border: 1px solid gray;
    }
  
    .fotter {
      border-radius: 15px;
      flex: 5;
      background: #fff;
      border: 1px solid gray;
      overflow: hidden;
    }
  }
  </style>